import React, {useCallback, useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import Form, {ButtonItem, ButtonOptions, EmailRule, Item, Label, RequiredRule} from 'devextreme-react/form'
import LoadIndicator from 'devextreme-react/load-indicator'
import {resetPassword} from '../../api/auth'
import './ResetPasswordForm.scss'
import {toast} from 'react-toastify'

const notificationText =
  "We've sent a link to reset your password. Check your inbox."

export default function ResetPasswordForm() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const formData = useRef({ email: '', password: '' })

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault()
      const { email } = formData.current
      setLoading(true)

      const result = await resetPassword(email)
      setLoading(false)

      if (result.isOk) {
        toast.success('Your credentials will be sent to your email address.')
        setTimeout(() => {
          navigate('/login')
        }, 1500)
      } else {
        toast.error(
          'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
        )
      }
    },
    [navigate]
  )

  return (
    <form className={'reset-password-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'email'}
          editorType={'dxTextBox'}
          editorOptions={emailEditorOptions}>
          <RequiredRule message="Email is required" />
          <EmailRule message="Email is invalid" />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            elementAttr={submitButtonAttributes}
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}>
            <span className="dx-button-text">
              {loading ? (
                <LoadIndicator width={'24px'} height={'24px'} visible={true} />
              ) : (
                'Get my Password'
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={'login-link'}>
            Return to <Link to="/login">Sign In</Link>
          </div>
        </Item>
      </Form>
    </form>
  )
}

const emailEditorOptions = {
  stylingMode: 'filled',
  placeholder: 'Email',
  mode: 'email'
}
const submitButtonAttributes = { class: 'submit-button' }
