import {FC, useState} from 'react'
import {CheckBox} from 'devextreme-react/check-box'

export const Territories: FC<{ data: any; onValueChange?: any }> = ({
  data,
  onValueChange
}) => {
  const [territory, setTerritory] = useState(data.entity)

  return (
    <>
      <div>
        <span className="dx-form-group-caption">Territories</span>
      </div>
      <div className="territories-wrapper">
        <div className="territories-controls">
          <div
            onClick={() => {
              const territoriesNew = territory.map((e: any) => {
                return { ...e, isActiveFlag: true }
              })
              setTerritory(territoriesNew)
              onValueChange(true, data.entity[0].stateId, true)
            }}
            className="territories-control">
            Select All
          </div>
          <div
            onClick={() => {
              const territoriesNew = territory.map((e: any) => {
                return { ...e, isActiveFlag: false }
              })
              setTerritory(territoriesNew)
              onValueChange(false, data.entity[0].stateId, true)
            }}
            className="territories-control">
            Unselect All
          </div>
        </div>
        <div className="territory-wrapper">
          {territory &&
            territory.map((item: any, index: number) => {
              return (
                <div key={index} className="territory-item dx-field">
                  <div className="dx-field-label">{item.stateId}</div>
                  <div className="dx-field-value">
                    <CheckBox
                      value={item.isActiveFlag}
                      onValueChange={(value: any) => {
                        const territoriesNew = territory.map((e: any) => {
                          return item.stateId === e.stateId
                            ? { ...e, isActiveFlag: value }
                            : e
                        })
                        setTerritory(territoriesNew)
                        onValueChange(value, item.stateId)
                      }}
                    />
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </>
  )
}
