import {AzureCommunicationTokenCredential, CommunicationUserIdentifier} from '@azure/communication-common'
import {
  AvatarPersonaData,
  ChatComposite,
  CompositeLocale,
  fromFlatCommunicationIdentifier,
  useAzureCommunicationChatAdapter
} from '@azure/communication-react'
import {PartialTheme, Theme} from '@fluentui/react'
import React, {useMemo} from 'react'
import {toast} from 'react-toastify'

export type ContainerProps = {
  userIdentifier: string
  token: string
  displayName: string
  endpointUrl: string
  threadId: string
  isView?: boolean
  fluentTheme?: PartialTheme | Theme
  errorBar?: boolean
  participants?: boolean
  topic?: boolean
  locale?: CompositeLocale
  formFactor?: 'desktop' | 'mobile'
}

export const ChatContainer = (props: ContainerProps): JSX.Element => {
  const credential = useMemo(() => {
    try {
      return new AzureCommunicationTokenCredential(props.token)
    } catch {
      console.error('Failed to construct token credential')
      toast.error(
        'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
      )
      return undefined
    }
  }, [props.token])

  const userId = useMemo(
    () =>
      fromFlatCommunicationIdentifier(
        props.userIdentifier
      ) as CommunicationUserIdentifier,
    [props.userIdentifier]
  )

  const adapter = useAzureCommunicationChatAdapter({
    endpoint: props.endpointUrl,
    userId,
    displayName: props.displayName,
    credential,
    threadId: props.threadId
  })

  const onFetchAvatarPersonaData = (userId: any): Promise<AvatarPersonaData> =>
    new Promise((resolve) => {
      return resolve({
        imageInitials: '',
        initialsColor: 'transparent'
      })
    })

  if (adapter) {
    return (
      <div
        className={`hq-chat ${props.isView ? 'hide-chat-input' : ''}`}
        style={{
          height: '335px',
          width: '100%'
        }}>
        <ChatComposite
          adapter={adapter}
          fluentTheme={props.fluentTheme}
          onFetchAvatarPersonaData={onFetchAvatarPersonaData}
          options={{
            errorBar: props.errorBar,
            topic: false
          }}
          locale={props.locale}
        />
      </div>
    )
  }
  if (credential === undefined) {
    return <>Failed to construct credential. Provided token is malformed.</>
  }
  return <>Initializing...</>
}
