import {FC, useEffect} from 'react'
import {useHubConnection} from 'shared/hooks/use-hub-connection'
import Notify from 'devextreme/ui/notify'
import {isChatIdentityExist} from '../../shared/functions'
import {chatLimitQuery} from '../../api/queries'

interface IMemberWithAdvisorCreateChatToast {
  tabs: any
  setTabs: any
  setChatVisible: any
  setMessage: any
  setChatData: any
}

export const MemberWithAdvisorCreateChatToast: FC<
  IMemberWithAdvisorCreateChatToast
  > = ({ tabs, setTabs, setChatVisible, setMessage, setChatData }) => {
  const hubConnection = useHubConnection()

  const onHiding = (memberWithAdvisorCreateChatHubMessage: any) => {
    if (
      !isChatIdentityExist(tabs, memberWithAdvisorCreateChatHubMessage.memberId)
    ) {
      const newTabs = tabs.map((item: any) => {
        return item
      })
      newTabs.push({
        memberId: memberWithAdvisorCreateChatHubMessage.memberId,
        memberName: memberWithAdvisorCreateChatHubMessage.memberName
      })
      setTabs(newTabs)
    }
    setChatData('')
    setChatVisible(true)
    setMessage({ memberId: memberWithAdvisorCreateChatHubMessage.memberId })
  }

  useEffect(() => {
    if (hubConnection) {
      const {
        memberWithAdvisorCreateChatHubMessage,
        setMemberWithAdvisorCreateChatHubMessage
      } = hubConnection
      if (memberWithAdvisorCreateChatHubMessage) {
        if (tabs.length > 3) {
          chatLimitQuery(
            memberWithAdvisorCreateChatHubMessage.memberId,
            memberWithAdvisorCreateChatHubMessage.memberName
          )
            .then((response: any) => {
            })
            .catch((err: any) => {
              console.error(err)
            })
          setMemberWithAdvisorCreateChatHubMessage('')
          return
        }
        setMessage({ memberId: memberWithAdvisorCreateChatHubMessage.memberId })
        Notify(
          {
            message: `Member entered into chat`,
            width: 250,
            type: 'info',
            onHiding: () => {
              onHiding(memberWithAdvisorCreateChatHubMessage)
            },
            closeOnClick: true,
            displayTime: 86400000,
            animation: {
              show: {
                type: 'fade',
                duration: 400,
                from: 0,
                to: 1
              },
              hide: { type: 'fade', duration: 40, to: 0 }
            }
          },
          {
            position: 'top right',
            direction: 'left-stack'
          }
        )
        setMemberWithAdvisorCreateChatHubMessage('')
      }
    }
  }, [hubConnection])

  return <></>
}
