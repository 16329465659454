import { useEffect } from 'react'
import styled from '@emotion/styled'
import useFormDataHandlerContext from '../../../shared/hooks/use-form-data-handler-context'
import DataGrid, { Column } from 'devextreme-react/data-grid'
import {
  useTransport,
  useTransportSWR
} from '../../../shared/hooks/use-transport'
import { useAuth } from '../../../contexts/auth'
import { Link, useParams } from 'react-router-dom'
import { apiDomain } from 'api/queries'

const ListContainer = styled.div`
  margin: 40px 40px 60px;
`

export const UsersSpacialList = () => {
  const { currentFormData, setCurrentFormData } = useFormDataHandlerContext()
  const { user } = useAuth()
  const { serviceTypeId } = useParams()

  useEffect(() => {
    const currentFormDataNew = Object.assign({}, currentFormData)
    currentFormDataNew.isFormPage = false
    setCurrentFormData(currentFormDataNew)
  }, [])

  const transport = useTransport(apiDomain)

  const { data: members } = useTransportSWR<any>(
    transport,
    `/Member/List/${serviceTypeId}?CallerId=${user?.userTypeId}&CallerTypeId=${user?.userId}`
  )

  const accounts = members && members.entity ? members.entity : []
  // const columns = ['ID', 'FirstName', 'LastName']

  return (
    <>
      <ListContainer>
        <DataGrid dataSource={accounts} keyExpr="memberId" showBorders={true}>
          <Column
            dataField="memberId"
            width={100}
            allowSorting={false}
            caption="ID"
          />
          <Column
            dataField="firstName"
            allowSorting={false}
            cellRender={(props) => (
              <Link to={`${props.data.memberId}`}>{props.value}</Link>
            )}
          />
          <Column
            dataField="lastName"
            allowSorting={false}
            cellRender={(props) => (
              <Link to={`${props.data.serviceTypeId}/${props.data.memberId}`}>
                {props.value}
              </Link>
            )}
          />
        </DataGrid>
      </ListContainer>
    </>
  )
}
