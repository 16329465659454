import {useEffect, useRef} from 'react'
import DataGrid, {
  Column,
  Editing,
  Export,
  FilterRow,
  Form,
  Item as ToolbarItem,
  Pager,
  Popup,
  Selection,
  Toolbar
} from 'devextreme-react/data-grid'
import {Item} from 'devextreme-react/form'
import {useAuth} from '../../../contexts/auth'
import {createStore} from 'devextreme-aspnet-data-nojquery'
import useFormDataHandlerContext from '../../../shared/hooks/use-form-data-handler-context'
import {apiDomain} from 'api/queries'
import {DataGridTitle, PageContainer} from 'shared/components/layout'

export const StateData = () => {
  const { setCurrentFormData } = useFormDataHandlerContext()

  useEffect(() => {
    setCurrentFormData({})
  }, [])

  const authToken = sessionStorage.getItem('authToken')

  const { user } = useAuth()
  const { current: dataSourceRef } = useRef(
    createStore({
      key: 'stateId',
      loadUrl:
        apiDomain +
        '/Api/Admin/ReferenceState/DataSource' +
        `?CallerId=${user?.userId}&CallerTypeId=${user?.userTypeId}`,
      onBeforeSend: (operation, ajaxSettings) => {
        ajaxSettings.headers = {
          Authorization: `Bearer ${authToken}`
        }
      },
      updateUrl: `${apiDomain}/Api/Admin/ReferenceState?CallerId=${user?.userId}&CallerTypeId=${user?.userTypeId}`
    })
  )

  return (
    <PageContainer>
      <DataGrid
        dataSource={dataSourceRef}
        remoteOperations={{ paging: true, sorting: true, filtering: true }}
        hoverStateEnabled={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        allowColumnReordering={true}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}>
        {user && user.permissionLevel >= 90 && <Export enabled={true} />}
        <FilterRow showOperationChooser={false} visible={true}></FilterRow>
        <Selection mode="single" />
        {user && user.permissionLevel >= 90 && (
          <Editing mode="popup" allowUpdating={true}>
            <Popup
              title="State Data"
              showTitle={true}
              width={400}
              height={200}
            />
            <Form>
              <Item itemType="group" colCount={2} colSpan={2}>
                <Item dataField="taxRate" />
                <Item dataField="hoiRate" />
              </Item>
            </Form>
          </Editing>
        )}
        <Column dataField="stateId" caption="ID" />
        <Column dataField="description" caption="State" />
        <Column dataField="taxRate" caption="Tax Rate" />
        <Column dataField="hoiRate" caption="HOI Rate" />

        <Toolbar>
          <ToolbarItem location="before">
            <DataGridTitle>State Data</DataGridTitle>
          </ToolbarItem>
          <Item name="exportButton" />
        </Toolbar>

        <Pager
          visible={true}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
      </DataGrid>
    </PageContainer>
  )
}
