import {
  Column,
  DataGrid,
  Export,
  FilterRow,
  Item,
  Selection,
  Summary,
  Toolbar,
  TotalItem
} from 'devextreme-react/data-grid'
import moment from 'moment'
import {useParams} from 'react-router-dom'
import {useAuth} from '../../contexts/auth'
import {DataGridTitle, PageContainer} from '../../shared/components/layout'
import useFormDataHandlerContext from '../../shared/hooks/use-form-data-handler-context'
import {useCallback, useEffect} from 'react'
import {createStore} from 'devextreme-aspnet-data-nojquery'
import {apiDomain} from '../../api/queries'
import {Workbook} from 'exceljs'
import {saveAs} from 'file-saver-es'
import {exportDataGrid} from 'devextreme/excel_exporter'

export const ReportsGrid = () => {
  const { setCurrentFormData } = useFormDataHandlerContext()

  useEffect(() => {
    setCurrentFormData({})
  }, [])
  const dateRender = (cellData: any) => {
    return moment(cellData.value).format('MM/DD/YYYY')
  }

  const { type } = useParams()
  let userType
  let userTypeString
  let userTypeNameField
  switch (type) {
    case 'hq-staff':
      userType = 'Advisor'
      userTypeString = 'HqStaff'
      userTypeNameField = 'homeQualStaffName'
      break
    case 'lenders':
      userType = 'Lender'
      userTypeString = 'Lender'
      userTypeNameField = 'lenderName'
      break
    case 'agents':
      userType = 'Agent'
      userTypeString = 'Agent'
      userTypeNameField = 'agentName'
      break
  }
  const authToken = sessionStorage.getItem('authToken')
  const { user } = useAuth()

  const dataSource = createStore({
    key: userTypeNameField,
    loadUrl:
      apiDomain +
      `/Api/Admin/Report/${userTypeString}/DataSource` +
      `?CallerId=${user?.userId}&CallerTypeId=${user?.userTypeId}`,
    onBeforeSend: (_, ajaxSettings) => {
      ajaxSettings.headers = {
        Authorization: `Bearer ${authToken}`
      }
    }
  })

  const percentRenderActive = (cellData: any) => {
    return (
      (cellData.data.countOfActive / cellData.data.countOfMemberId) *
      100
    ).toFixed(1)
  }

  const percentRenderLost = (cellData: any) => {
    return (
      (cellData.data.countOfLost / cellData.data.countOfMemberId) *
      100
    ).toFixed(1)
  }

  const percentRenderClosed = (cellData: any) => {
    return (
      (cellData.data.countOfClosed / cellData.data.countOfMemberId) *
      100
    ).toFixed(1)
  }

  const roundValue = (data: any) => {
    return `${data.value.toFixed(1)}`
  }

  const renderSum = (data: any) => {
    return `${data.value}`
  }

  const onExporting = useCallback((e: any) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Employees')
    exportDataGrid({
      component: e.component,
      worksheet: worksheet
    }).then(function () {
      workbook.csv.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Report.csv'
        )
      })
    })

    e.cancel = true
  }, [])

  return (
    <>
      <PageContainer>
        <DataGrid
          dataSource={dataSource}
          onExporting={onExporting}
          remoteOperations={{
            paging: true,
            filtering: true,
            sorting: true
          }}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          allowColumnReordering={true}
          showBorders={true}
          showColumnLines={true}
          showRowLines={true}>
          <FilterRow showOperationChooser={false} visible={true} />
          <Selection mode="single" />
          <Export enabled={true} formats={['csv']} />
          <Column
            dataField={userTypeNameField}
            alignment="left"
            caption={userType}
          />
          <Column dataField="countOfMemberId" caption="Members" />
          <Column dataField="countOfActive" caption="Active" />
          <Column
            dataField="countOfActivePercentage"
            caption="%"
            cellRender={percentRenderActive}
          />
          <Column dataField="countOfLost" caption="Lost" />
          <Column
            dataField="countOfLostPercentage"
            caption="%"
            cellRender={percentRenderLost}
          />
          <Column dataField="countOfClosed" caption="Closed" />
          <Column
            dataField="countOfClosedPercentage"
            caption="%"
            cellRender={percentRenderClosed}
          />
          <Column dataField="revenue" caption="Revenue" />
          <Summary>
            <TotalItem
              column="countOfMemberId"
              summaryType="sum"
              customizeText={renderSum}
            />
            <TotalItem
              column="countOfActive"
              summaryType="sum"
              customizeText={renderSum}
            />
            <TotalItem
              column="countOfActivePercentage"
              customizeText={roundValue}
              summaryType="avg"
            />
            <TotalItem
              column="countOfLost"
              summaryType="sum"
              customizeText={renderSum}
            />
            <TotalItem
              column="countOfLostPercentage"
              customizeText={roundValue}
              summaryType="avg"
            />
            <TotalItem
              column="countOfClosed"
              summaryType="sum"
              customizeText={renderSum}
            />
            <TotalItem
              column="countOfClosedPercentage"
              customizeText={roundValue}
              summaryType="avg"
            />
            <TotalItem
              column="revenue"
              summaryType="sum"
              customizeText={renderSum}
            />
          </Summary>
          <Toolbar>
            <Item location="before">
              <DataGridTitle>{userType} reports</DataGridTitle>
            </Item>
            <Item name="exportButton" />
          </Toolbar>
        </DataGrid>
      </PageContainer>
    </>
  )
}
