import {FC} from 'react'
import moment from 'moment'
import CustomStore from 'devextreme/data/custom_store'
import {Column, DataGrid, FilterRow, Item, Pager, Selection, Toolbar} from 'devextreme-react/data-grid'
import {useNavigate} from 'react-router-dom'
import {isAgentOrLender, phoneFormat} from 'shared/functions'
import {DataGridTitle} from 'shared/components/layout'
import {useAuth} from "../../../contexts/auth";

export const MemberDataGrid: FC<{
  dataSource: CustomStore
  isFullPath?: boolean
  title?: string
  showCoBorrower?: boolean
}> = ({ dataSource, isFullPath, title, showCoBorrower }) => {
  const navigate = useNavigate()
  const phoneRender = (cellData: any) => {
    if (!cellData.value) {
      return
    }
    return phoneFormat(cellData.value)
  }
  const { user } = useAuth()

  const ownedRender = (cellData: any) => {
    if (!cellData.value) {
      return ''
    }
    return <div className="owned" />
  }
  const dateRender = (cellData: any) => {
    return moment(cellData.value).format('MM/DD/YYYY')
  }

  return (
    <DataGrid
      dataSource={dataSource}
      remoteOperations={{ paging: true, sorting: true, filtering: true }}
      hoverStateEnabled={true}
      allowColumnResizing={true}
      columnAutoWidth={true}
      columnHidingEnabled={true}
      allowColumnReordering={true}
      showBorders={true}
      showColumnLines={true}
      showRowLines={true}
      onSelectionChanged={(value) => {
        const serviceTypeId = value.selectedRowsData[0]?.serviceTypeId
        const memberId = value.selectedRowsData[0]?.memberId
        if (!serviceTypeId || !memberId) {
          return
        }
        const path = isFullPath ? `${serviceTypeId}/${memberId}` : `${memberId}`
        navigate(path)
      }}>
      <FilterRow showOperationChooser={false} visible={true} />
      <Selection mode="single" />
      {
        user && !isAgentOrLender(user?.userTypeId) && <Column
            dataField="homeQualStaffIdIsOwnerFlag"
            caption="Owned"
            cellRender={ownedRender}
            trueText="Yes"
            falseText="No"
        />
      }
      <Column
        dataField="serviceTypeDescription"
        alignment="left"
        caption="Service"
      />
      <Column dataField="memberId" alignment="left" caption="Member ID" />
      <Column dataField="firstName" caption="First Name" />
      <Column dataField="lastName" caption="Last Name" />
      <Column dataField="emailAddress" caption="Email" />
      <Column
        dataField="cellPhone"
        caption="Phone"
        dataType="string"
        alignment="left"
        cellRender={phoneRender}
      />
      <Column
        caption="Last Activity"
        dataField="lastUpdatedDate"
        cellRender={dateRender}
      />
      <Column
        caption="Status"
        alignment="left"
        dataField="memberStatusDescription"
      />
      {showCoBorrower && (
        <Column caption="Co-Borrower First Name" dataField="coFirstName" />
      )}
      {showCoBorrower && (
        <Column caption="Co-Borrower Last Name" dataField="coLastName" />
      )}

      <Toolbar>
        <Item location="before">
          <DataGridTitle>{title}</DataGridTitle>
        </Item>
      </Toolbar>

      <Pager
        visible={true}
        showPageSizeSelector={true}
        showInfo={true}
        showNavigationButtons={true}
      />
    </DataGrid>
  )
}
