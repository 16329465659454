import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import type { NavigationContextType } from '../types'

const NavigationContext = createContext<NavigationContextType>(
  {} as NavigationContextType
)
const useNavigation = () => useContext(NavigationContext)

function NavigationProvider(props: React.PropsWithChildren<unknown>) {
  const [navigationData, setNavigationData] = useState({ currentPath: '' })

  return (
    <NavigationContext.Provider
      value={{ navigationData, setNavigationData }}
      {...props}
    />
  )
}

function withNavigationWatcher(Component: React.ElementType) {
  const WrappedComponent = function (props: Record<string, unknown>) {
    const { setNavigationData } = useNavigation()
    const location = useLocation()

    useEffect(() => {
      setNavigationData!({ currentPath: location.pathname })
    }, [location.pathname, setNavigationData])

    return <Component {...props} />
  }
  return <WrappedComponent />
}

export { NavigationProvider, useNavigation, withNavigationWatcher }
