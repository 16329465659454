import React, {FC} from 'react'
import {Column, DataGrid} from 'devextreme-react/data-grid'
import Button from 'devextreme-react/button'
import {Switch} from 'devextreme-react/switch'
import {memberUpdateQuery, verseOffQuery, verseOnQuery} from '../../../../api/queries'
import useFormDataHandlerContext from "../../../../shared/hooks/use-form-data-handler-context";
import {toast} from "react-toastify";
import {useAuth} from "../../../../contexts/auth";

export const NotesOrTasksGrid: FC<{
  dataSource: any
  colums: any
  setFormData: any
  togglePopup: any
  addButtonShow?: any
  switchShow?: any
}> = ({
  dataSource,
  colums,
  setFormData,
  togglePopup,
  addButtonShow,
  switchShow
}) => {
  const { currentFormData, setCurrentFormData } = useFormDataHandlerContext()
  const { user } = useAuth()
  const onChangeVerse = (value: boolean) => {
    if (!currentFormData || !user) {
      return
    }

    if (value) {
      const verseOnQueryParams = {
        firstName: currentFormData.formDataNew.firstName,
        lastName: currentFormData.formDataNew.lastName,
        emailAddress: currentFormData.formDataNew.emailAddress,
        cellPhone: currentFormData.formDataNew.cellPhone,
        memberId: currentFormData.formDataNew.memberId
      }
      verseOnQuery(verseOnQueryParams)
        .then((response: any) => {
          memberUpdateQuery({...currentFormData.formDataNew, verseActivatedFlag: true}, user?.userId, user?.userTypeId)
            .then((response: any) => {
              const currentFormDataNew = Object.assign({}, currentFormData)
              currentFormDataNew.formDataNew = {...currentFormDataNew.formDataNew, verseActivatedFlag: true}
              setCurrentFormData(currentFormDataNew)
            })
            .catch((err: any) => {
              console.error(err)
              toast.error(
                'Verse could not act on your request'
              )
            })
        })
        .catch((err: any) => {
          console.error(err)
          toast.error(
            'Verse could not act on your request'
          )
        })
    } else {
      verseOffQuery(currentFormData.formDataNew.emailAddress)
        .then((response: any) => {
          memberUpdateQuery({...currentFormData.formDataNew, verseActivatedFlag: false}, user?.userId, user?.userTypeId)
            .then((response: any) => {
              const currentFormDataNew = Object.assign({}, currentFormData)
              currentFormDataNew.formDataNew = {...currentFormDataNew.formDataNew, verseActivatedFlag: false}
              setCurrentFormData(currentFormDataNew)
            })
            .catch((err: any) => {
              console.error(err)
              toast.error(
                'Verse could not act on your request'
              )
            })
        })
        .catch((err: any) => {
          console.error(err)
          toast.error(
            'Verse could not act on your request'
          )
        })
    }
  }

  return (
    <div className="notesOrTasksGridWrap">
      <div className="textAlignEnd">
        {addButtonShow && (
          <Button
            icon="add"
            stylingMode="text"
            onClick={() => {
              setFormData({
                note: null,
                date: null,
                closed: false,
                exist: false
              })
              togglePopup()
            }}
          />
        )}
        {switchShow && (
          <div className="dx-field dx-field-switch textAlignEnd">
            <div className="dx-field-label dx-field-label-switch">
              Verse Active
            </div>
            <div className="dx-field-value dx-field-value-switch">
              <Switch onValueChange={onChangeVerse} defaultValue={false} value={currentFormData?.formDataNew?.verseActivatedFlag}/>
            </div>
          </div>
        )}
      </div>
      <DataGrid
        dataSource={dataSource}
        remoteOperations={{ paging: true, sorting: true, filtering: true }}
        hoverStateEnabled={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        allowColumnReordering={true}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        height="100%"
        onCellClick={(value) => {
          setFormData({
            note: value.data.note || value.data.task,
            date: value.data.dueDate,
            closed: !value.data.isActiveFlag,
            exist: true,
            createdByUserId: value.data.createdByUserId,
            completed: !value.data.isActiveFlag,
            memberTaskId: value.data.memberTaskId
          })
          togglePopup()
        }}>
        {colums.map((column: any, index: number) => {
          return (
            <Column
              key={index}
              dataField={column.field}
              caption={column.name}
              cellRender={column.render}
              width={column.width}
              alignment={column.alignment}
              defaultSortIndex={column.defaultSortIndex || false}
              defaultSortOrder={column.defaultSortOrder || false}
            />
          )
        })}
      </DataGrid>
    </div>
  )
}
