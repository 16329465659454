export enum AccountRouteParam {
  HqStaff = 'hq-staff',
  Lenders = 'lenders',
  Agents = 'agents'
}

export enum UserTypeId {
  Member = 1,
  Agent = 2,
  Lender = 3,
  Advisor = 11,
  Manager = 12,
  Accounting = 13,
  Administrator = 14,
  Executive = 15,
  System = 99
}

export enum AccountIdKey {
  HqStaffId = 'homeQualStaffId',
  AgentId = 'agentId',
  LenderId = 'lenderId'
}

export enum HubMethodId {
  RegisterMember = '0',
  MemberCreateChat = '1',
  VisitorCreateChat = '2',
  CloseChat = '3',
  MemberWithAdvisorCreateChat = '4'
}
