import { FC } from 'react'
import { GlobalSettingsForm } from 'features/administration/global-settings/components/global-settings-form'
import { PageContainer } from 'shared/components/layout'

export const GlobalSettings: FC = () => {
  return (
    <>
      <PageContainer>
        <GlobalSettingsForm />
      </PageContainer>
    </>
  )
}
