import React, {useEffect, useMemo, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import styled from '@emotion/styled'
import Form, {EmailRule, GroupItem, RequiredRule, SimpleItem} from 'devextreme-react/form'
import {useTransport, useTransportSWR} from '../../../shared/hooks/use-transport'
import {useAuth} from '../../../contexts/auth'
import {LoadPanel} from 'devextreme-react/load-panel'
import {memberInsertQuery, memberUpdateQuery} from '../../../api/queries'
import useFormDataHandlerContext from '../../../shared/hooks/use-form-data-handler-context'
import {apiDomain} from 'api/queries'
import {
  isAdministratorPermission,
  isAgentOrLender,
  isHqStaffHandler,
  isHqStaffPermission
} from '../../../shared/functions'
import {Notes} from './notes-and-tasks/notes'
import {Tasks} from './notes-and-tasks/tasks'
import {toast} from 'react-toastify'

const ListContainer = styled.div`
  margin: 40px 40px 60px;
`

export const UserPortalAdvisorDetail = () => {
  const { currentFormData, setCurrentFormData } = useFormDataHandlerContext()
  const navigate = useNavigate()
  const { id } = useParams()
  const transport = useTransport(apiDomain)

  const { data: member } = useTransportSWR<any>(transport, `/Member/${id}`)

  const [isVisible, setIsVisible] = useState(false)
  const { user } = useAuth()

  const initialData = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    cellPhone: '',
    callerId: user?.userId,
    userTypeId: user?.userTypeId
  }

  const formData = useMemo(() => {
    const currentFormDataNew = Object.assign({}, currentFormData)
    return member && member.entity
      ? member.entity
      : currentFormDataNew.formDataNew
      ? currentFormDataNew.formDataNew
      : initialData
  }, [member])

  const formSaveHandler = (data: any) => {
    if (!data || !user) {
      return
    }
    memberUpdateQuery(data, user?.userId, user?.userTypeId)
      .then((response: any) => {
        console.log(response)
      })
      .catch((err: any) => {
        console.error(err)
        toast.error(
          'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
        )
      })
  }

  const formSaveHandlerNew = (data: any) => {
    memberInsertQuery(data, '8')
      .then((response: any) => {
        if (response.data.entity) {
          navigate(`/user-portal/8/${response.data.entity.memberId}`)
        } else if (response.data.error) {
          toast.error(
            response.data.error?.uiDisplayMessage ||
            'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
          )
        }
        console.log(response)
      })
      .catch((err: any) => {
        console.error(err)
        toast.error(
          'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
        )
      })
  }

  useEffect(() => {
    const currentFormDataNew = Object.assign({}, currentFormData)
    currentFormDataNew.isListPage = false
    currentFormDataNew.territories = false
    currentFormDataNew.imageFile = false
    currentFormDataNew.addUrl = false
    currentFormDataNew.isFormPage = true
    currentFormDataNew.formSaveHandler =
      member && member.entity ? formSaveHandler : formSaveHandlerNew
    setCurrentFormData(currentFormDataNew)
  }, [member])

  const onFieldDataChangedHandler = (data: any) => {
    if (!formData || !data.value === null || data.value === undefined) {
      return
    }
    const currentFormDataNew = Object.assign({}, currentFormData)
    currentFormDataNew.isFormDataChanged = true
    formData[data.dataField] = data.value
    if (data.dataField === 'Phone') {
      formData.cellPhone = data.value || null
      formData.Phone = data.value || null
    }

    if (data.dataField === 'Email') {
      formData.emailAddress = data.value || null
      formData.Email = data.value || null
    }

    if (data.dataField === 'Preference') {
      const currentCommunicationPreferences =
        communicationPreferences.entity.filter(
          (communicationPreference: any) => {
            return communicationPreference.description === data.value
          }
        )
      formData.communicationPreferenceId =
        currentCommunicationPreferences[0] &&
        currentCommunicationPreferences[0].referenceCommunicationPreferenceId
      formData.Preference =
        currentCommunicationPreferences[0] &&
        currentCommunicationPreferences[0].referenceCommunicationPreferenceId
    }

    if (data.dataField === 'stateId') {
      const currentStateId = states.states.filter((state: any) => {
        return state.description.toLowerCase() === data.value.toLowerCase()
      })
      formData.stateId = currentStateId[0] ? currentStateId[0].stateId : null
    }

    if (data.dataField === 'sourceId') {
      const currentsourceId = systemUrlSources.entity.filter(
        (systemUrlSource: any) => {
          return systemUrlSource.description === data.value
        }
      )
      formData.sourceId = currentsourceId[0].referenceMemberSourceUrlId
    }

    if (data.dataField === 'communicationOptOutStatusId') {
      const currentOptOutStatus = optOutStatuses.entity.filter(
        (optOutStatus: any) => {
          return optOutStatus.description === data.value
        }
      )
      formData.communicationOptOutStatusId =
        currentOptOutStatus[0] && currentOptOutStatus[0].referenceOptOutStatusId
    }

    if (data.dataField === 'languageId') {
      const currentLanguageId = languages.entity.filter((language: any) => {
        return language.description === data.value
      })
      formData.languageId =
        currentLanguageId[0] && currentLanguageId[0].referenceLanguageId
    }

    if (data.dataField === 'homeQualStaffId') {
      const currentAdvisor = advisors.data.filter((advisor: any) => {
        return `${advisor.firstName} ${advisor.lastName}` === data.value
      })
      formData.homeQualStaffId =
        currentAdvisor[0] && currentAdvisor[0].homeQualStaffId
    }

    currentFormDataNew.formDataNew = formData

    currentFormDataNew.validate = data.component
    setCurrentFormData(currentFormDataNew)
  }

  const { data: states } = useTransportSWR<any>(transport, `/ReferenceState`)
  let statesDescription = []
  let currentState = []
  if (states) {
    statesDescription = states.states.map((state: any) => {
      return state.description
    })
    if (formData.stateId) {
      currentState = states.states.filter((state: any) => {
        return state.stateId.toLowerCase() === formData.stateId.toLowerCase()
      })
    }
  }

  const { data: communicationPreferences } = useTransportSWR<any>(
    transport,
    `/ReferenceCommunicationPreference`
  )

  let communicationPreferenceDescription = []
  let currentCommunicationPreference = []

  if (communicationPreferences) {
    communicationPreferenceDescription = communicationPreferences.entity.map(
      (communicationPreference: any) => {
        return communicationPreference.description
      }
    )
    if (
      formData.communicationPreferenceId !== null &&
      formData.communicationPreferenceId !== undefined
    ) {
      currentCommunicationPreference = communicationPreferences.entity.filter(
        (communicationPreference: any) => {
          return (
            +communicationPreference.referenceCommunicationPreferenceId ===
            +formData.communicationPreferenceId
          )
        }
      )
    }
  }

  const { data: optOutStatuses } = useTransportSWR<any>(
    transport,
    `/ReferenceOptOutStatus`
  )

  let optOutStatusDescription = []
  let currentOptOutStatus = []

  if (optOutStatuses) {
    optOutStatusDescription = optOutStatuses.entity.map((optOutStatus: any) => {
      if (
        !formData.communicationOptOutStatusId ||
        isAdministratorPermission(user?.permissionLevel)
      ) {
        return optOutStatus.description
      }
      if (
        formData.communicationOptOutStatusId !== 4 &&
        isHqStaffPermission(user?.permissionLevel) &&
        optOutStatus.referenceOptOutStatusId !== 0
      ) {
        return optOutStatus.description
      } else if (
        formData.communicationOptOutStatusId === 4 &&
        isHqStaffPermission(user?.permissionLevel) &&
        optOutStatus.referenceOptOutStatusId === 4
      ) {
        return optOutStatus.description
      }
    })
    optOutStatusDescription = optOutStatusDescription.filter((optOutStatus: any) => {
      return optOutStatus
    })

    if (
      formData.communicationOptOutStatusId !== null &&
      formData.communicationOptOutStatusId !== undefined
    ) {
      currentOptOutStatus = optOutStatuses.entity.filter(
        (optOutStatus: any) => {
          return (
            +optOutStatus.referenceOptOutStatusId ===
            +formData.communicationOptOutStatusId
          )
        }
      )
    }
  }

  const { data: languages } = useTransportSWR<any>(
    transport,
    `/ReferenceLanguage`
  )

  let languageDescription = []
  let currentLanguage = []

  if (languages) {
    languageDescription = languages.entity.map((language: any) => {
      return language.description
    })
    if (formData.languageId !== null && formData.languageId !== undefined) {
      currentLanguage = languages.entity.filter((language: any) => {
        return +language.referenceLanguageId === +formData.languageId
      })
    }
  }

  const { data: advisors } = useTransportSWR<any>(
    transport,
    `/HomeQualStaff/DataSource/11?CallerId=${user?.userId}&CallerTypeId=${user?.userTypeId}&sort=[{"selector":"firstName","desc":false}]`
  )

  let advisorsDescription = []
  let currentAdvisor = []
  if (advisors) {
    advisorsDescription = advisors.data.map((advisor: any) => {
      return `${advisor.firstName} ${advisor.lastName}`
    })
    if (
      formData.homeQualStaffId !== null &&
      formData.homeQualStaffId !== undefined
    ) {
      currentAdvisor = advisors.data.filter((advisor: any) => {
        return advisor.homeQualStaffId === formData.homeQualStaffId
      })
    }
  }

  const { data: systemUrlSources } = useTransportSWR<any>(
    transport,
    `/ReferenceMemberSourceUrl`
  )

  let systemUrlSourceDescription = []
  let currentSystemUrlSource = []

  if (systemUrlSources) {
    systemUrlSourceDescription = systemUrlSources.entity.map(
      (systemUrlSource: any) => {
        return systemUrlSource.description
      }
    )
    if (formData.sourceId !== null && formData.sourceId !== undefined) {
      currentSystemUrlSource = systemUrlSources.entity.filter(
        (systemUrlSource: any) => {
          return (
            +systemUrlSource.referenceMemberSourceUrlId === +formData.sourceId
          )
        }
      )
    }
  }

  const isHqStaff = user && isHqStaffHandler(user?.userTypeId)

  return (
    <>
      <ListContainer>
        {id &&
        systemUrlSources &&
        states &&
        optOutStatuses &&
        languages &&
        communicationPreferences &&
        advisors &&
        member ? (
          <Form
            labelLocation="left"
            formData={formData}
            onContentReady={() => {
              setIsVisible(true)
            }}
            visible={isVisible}
            onFieldDataChanged={onFieldDataChangedHandler}
            showColonAfterLabel={false}
            showRequiredMark={false}>
            <GroupItem colCount={2}>
              <GroupItem caption="Advisor Request">
                <SimpleItem dataField="firstName">
                  <RequiredRule message="First Name is required" />
                </SimpleItem>
                <SimpleItem dataField="lastName">
                  <RequiredRule message="Last Name is required" />
                </SimpleItem>

                <SimpleItem
                  visible={
                    (formData.communicationOptOutStatusId !== 3 &&
                      formData.communicationOptOutStatusId !== 4) ||
                    isAdministratorPermission(user?.permissionLevel)
                  }
                  dataField="Phone"
                  editorOptions={{
                    disabled:
                      !isHqStaffPermission(user?.permissionLevel) &&
                      !isAgentOrLender(user?.userTypeId),
                    value: formData.cellPhone
                      ? formData.cellPhone.toString()
                      : '',
                    mask: '(X00) 000-0000',
                    maskRules: { X: /[1-9]/ }
                  }}
                />

                <SimpleItem
                  visible={
                    (formData.communicationOptOutStatusId !== 1 &&
                      formData.communicationOptOutStatusId !== 4) ||
                    isAdministratorPermission(user?.permissionLevel)
                  }
                  dataField="Email"
                  editorOptions={{
                    disabled:
                      !isHqStaffPermission(user?.permissionLevel) &&
                      !isAgentOrLender(user?.userTypeId),
                    value:
                      formData && formData.emailAddress
                        ? formData.emailAddress
                        : null
                  }}>
                  <RequiredRule message="Email is required" />
                  <EmailRule message="Email is invalid" />
                </SimpleItem>

                <SimpleItem
                  editorType="dxSelectBox"
                  editorOptions={{
                    disabled: +id === 0,
                    value:
                      currentCommunicationPreference[0] &&
                      currentCommunicationPreference[0].description,
                    items: communicationPreferenceDescription
                  }}
                  dataField="Preference"
                />
                <SimpleItem
                  editorOptions={{ disabled: +id === 0 }}
                  dataField="address"
                />
                <SimpleItem
                  editorOptions={{ disabled: +id === 0 }}
                  dataField="city"
                />
                <GroupItem colCount={2}>
                  <SimpleItem
                    label={{ text: 'State' }}
                    editorType="dxSelectBox"
                    editorOptions={{
                      disabled: +id === 0,
                      value: currentState[0] && currentState[0].description,
                      items: statesDescription
                    }}
                    dataField="stateId"
                  />
                  <SimpleItem
                    editorOptions={{ disabled: +id === 0 }}
                    dataField="zipCode"
                  />
                </GroupItem>
              </GroupItem>
              <GroupItem caption="Status">
                <GroupItem>
                  <GroupItem colCount={2}>
                    <SimpleItem
                      editorOptions={{ disabled: true }}
                      dataField="memberId"
                    />
                    <SimpleItem
                      editorOptions={{ disabled: true }}
                      dataField="createdDate"
                      editorType="dxDateBox"
                      label={{ text: 'Since' }}
                    />
                  </GroupItem>
                  <SimpleItem
                    label={{ text: 'Service' }}
                    editorOptions={{ disabled: true }}
                    dataField="serviceTypeDescription"
                  />
                  <SimpleItem
                    label={{ text: 'Source' }}
                    editorType="dxSelectBox"
                    editorOptions={{
                      disabled: +id === 0,
                      value:
                        currentSystemUrlSource[0] &&
                        currentSystemUrlSource[0].description,
                      items: systemUrlSourceDescription
                    }}
                    dataField="sourceId"
                  />
                  <GroupItem colCount={2}>
                    <SimpleItem
                      label={{ text: 'Activity' }}
                      editorType="dxDateBox"
                      editorOptions={{ disabled: true }}
                      dataField="lastUpdatedDate"
                    />
                    <SimpleItem
                      editorType="dxCheckBox"
                      editorOptions={{
                        value: formData.isActiveFlag || false,
                        disabled: true
                      }}
                      label={{ text: 'Active' }}
                      dataField="isActiveFlag"
                    />
                  </GroupItem>
                  <GroupItem colCount={2}>
                    <SimpleItem
                      cssClass={currentOptOutStatus[0].referenceOptOutStatusId === 4 ? 'colorRed' : ''}
                      label={{ text: 'Opt-Out' }}
                      editorType="dxSelectBox"
                      editorOptions={{
                        disabled: +id === 0,
                        value:
                          currentOptOutStatus[0] &&
                          currentOptOutStatus[0].description,
                        items: optOutStatusDescription
                      }}
                      dataField="communicationOptOutStatusId"
                    />
                    <SimpleItem
                      label={{ text: 'Lang' }}
                      editorType="dxSelectBox"
                      editorOptions={{
                        disabled: +id === 0,
                        value:
                          currentLanguage[0] && currentLanguage[0].description,
                        items: languageDescription
                      }}
                      dataField="languageId"
                    />
                  </GroupItem>
                  <SimpleItem
                    label={{ text: 'Advisor' }}
                    editorType="dxSelectBox"
                    editorOptions={{
                      disabled: +id === 0,
                      value:
                        currentAdvisor[0] &&
                        `${currentAdvisor[0].firstName} ${currentAdvisor[0].lastName}`,
                      items: advisorsDescription
                    }}
                    dataField="homeQualStaffId"
                  />
                </GroupItem>
              </GroupItem>
              {isHqStaff && (
                <GroupItem caption="Notes">
                  <Notes memberId={id} />
                </GroupItem>
              )}
              {isHqStaff && (
                <GroupItem caption="Tasks">
                  <Tasks memberId={id} />
                </GroupItem>
              )}
            </GroupItem>
          </Form>
        ) : (
          <LoadPanel
            shadingColor="rgba(255,255,255,0.4)"
            visible={true}
            showIndicator={true}
            shading={true}
            showPane={true}
          />
        )}
      </ListContainer>
    </>
  )
}
