import React, {memo, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import styled from '@emotion/styled'
import Form, {EmailRule, GroupItem, RequiredRule, SimpleItem} from 'devextreme-react/form'
import useFormDataHandlerContext from '../../../shared/hooks/use-form-data-handler-context'
import {memberInsertQuery} from '../../../api/queries'
import {toast} from 'react-toastify'

const ListContainer = styled.div`
  margin: 40px 40px 60px;
`

export const UserPortalAddMember = memo(() => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { currentFormData, setCurrentFormData } = useFormDataHandlerContext()

  const initialData = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    cellPhone: ''
  }

  const formSaveHandler = (data: any) => {
    if (!data || !id) {
      return
    }
    memberInsertQuery(data, id)
      .then((response: any) => {
        if (response.data.entity) {
          navigate(`/user-portal/${id}/${response.data.entity.memberId}`)
        }
        console.log(response)
      })
      .catch((err: any) => {
        console.error(err)
        toast.error(
          'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
        )
      })
  }

  useEffect(() => {
    const currentFormDataNew = Object.assign({}, currentFormData)
    currentFormDataNew.isListPage = false
    currentFormDataNew.territories = false
    currentFormData.imageFile = false
    currentFormDataNew.territorySave = false
    currentFormDataNew.addUrl = false
    currentFormDataNew.isFormPage = true
    currentFormDataNew.formSaveHandler = formSaveHandler
    setCurrentFormData(currentFormDataNew)
  }, [])

  const currentFormDataNew = Object.assign({}, currentFormData)
  const formData = currentFormDataNew.formDataNew
    ? currentFormDataNew.formDataNew
    : initialData

  const onFieldDataChangedHandler = (data: any) => {
    const currentFormDataNew = Object.assign({}, currentFormData)
    currentFormDataNew.isFormDataChanged = true
    currentFormDataNew.formData = initialData
    formData[data.dataField as keyof typeof formData] = data.value
    currentFormDataNew.formDataNew = formData
    const validate = data.component.validate()
    if (!validate.isValid) {
      return
    }
    currentFormDataNew.isFormDataChanged = validate.isValid
    setCurrentFormData(currentFormDataNew)
  }

  let title
  switch (id) {
    case '3':
      title = 'Refinance'
      break
    case '5':
      title = 'Buy Home Request'
      break
    case '6':
      title = 'Lender Request'
      break
    case '7':
      title = 'Agent Request'
      break
    case '8':
      title = 'Advisor Request'
      break
  }

  return (
    <ListContainer>
      <Form
        labelLocation="left"
        formData={formData}
        onFieldDataChanged={onFieldDataChangedHandler}
        showColonAfterLabel={false}
        showRequiredMark={false}>
        <GroupItem caption={`Add ${title}`}>
          <SimpleItem dataField="firstName">
            <RequiredRule message="First Name is required" />
          </SimpleItem>
          <SimpleItem dataField="lastName">
            <RequiredRule message="Last Name is required" />
          </SimpleItem>
          <SimpleItem
            dataField="cellPhone"
            editorOptions={{
              value:
                formData && formData.cellPhone !== null
                  ? formData.cellPhone.toString()
                  : '',
              mask: '(X00) 000-0000',
              maskRules: { X: /[1-9]/ }
            }}
          />
          <SimpleItem dataField="emailAddress">
            <RequiredRule message="Email is required" />
            <EmailRule message="Email is invalid" />
          </SimpleItem>
        </GroupItem>
      </Form>
    </ListContainer>
  )
})
