import React, {useEffect, useRef} from 'react'
import useFormDataHandlerContext from '../../shared/hooks/use-form-data-handler-context'
import {Column, DataGrid, FilterRow, Selection} from 'devextreme-react/data-grid'
import {useNavigate} from 'react-router-dom'
import moment from 'moment'
import {createStore} from 'devextreme-aspnet-data-nojquery'
import {apiDomain} from '../../api/queries'
import {useAuth} from '../../contexts/auth'
import Form, {GroupItem} from 'devextreme-react/form'
import {useTransport, useTransportSWR} from '../../shared/hooks/use-transport'
import {isAgentOrLender, isHqStaffHandler, isHqStaffPermissionLessThan70} from '../../shared/functions'
import {AdminDashboard} from './AdminDashboard'

function Home() {
  const { setCurrentFormData } = useFormDataHandlerContext()
  const navigate = useNavigate()
  const transport = useTransport(apiDomain)

  useEffect(() => {
    setCurrentFormData({})
  }, [])

  const { user } = useAuth()

  const dateRender = (cellData: any) => {
    return moment(cellData.value).format('MM/DD/YYYY')
  }

  const authToken = sessionStorage.getItem('authToken')

  const { current: dataSourceRef } = useRef(
    createStore({
      key: 'memberId',
      loadUrl:
        apiDomain +
        '/Api/Admin/Member/DataSource' +
        `/${user?.userTypeId}/${user?.userId}` +
        `?CallerId=${user?.userId}&CallerTypeId=${user?.userTypeId}`,
      onBeforeSend: (_, ajaxSettings) => {
        ajaxSettings.headers = {
          Authorization: `Bearer ${authToken}`
        }
      }
    })
  )

  const textRender = (cellData: any) => {
    if (!user) {
      return
    }
    return (
      <div className="textRender">
        <div className="textRenderFrom">{`Assigned To: ${user.userName}`}</div>
        <div className="textRenderText">{cellData.value}</div>
      </div>
    )
  }

  const closedRender = (cellData: any) => {
    return cellData.value ? moment(cellData.value).format('MM/DD/YYYY') : 'OPEN'
  }

  const colums = [
    {
      name: 'Due Date',
      field: 'dueDate',
      render: dateRender,
      width: '120px',
      alignment: 'center',
      defaultSortOrder: 'asc'
    },
    {
      name: 'Task',
      field: 'task',
      render: textRender,
      alignment: 'left'
    },
    {
      name: 'Closed',
      field: 'completedDate',
      render: closedRender,
      width: '100px',
      alignment: 'center'
    }
  ]

  const { data: dataSource } = useTransportSWR<any>(
    transport,
    `/MemberTask/${user?.userTypeId}/${user?.userId}?CallerId=${user?.userId}&CallerTypeId=${user?.userTypeId}`
  )

  return (
    <>
      <h2 className={'content-block'}>Dashboard</h2>
      {user &&
      isHqStaffHandler(user?.userTypeId) &&
      isHqStaffPermissionLessThan70(user.permissionLevel) ? (
        <div className={'content-block'}>
          <Form>
            <GroupItem colCount={2}>
              <GroupItem caption="Members">
                <DataGrid
                  dataSource={dataSourceRef}
                  remoteOperations={{
                    paging: true,
                    filtering: true,
                    sorting: true
                  }}
                  hoverStateEnabled={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  columnHidingEnabled={true}
                  allowColumnReordering={true}
                  showBorders={true}
                  showColumnLines={true}
                  showRowLines={true}
                  onSelectionChanged={(value) => {
                    const serviceTypeId =
                      value.selectedRowsData[0]?.serviceTypeId
                    const memberId = value.selectedRowsData[0]?.memberId
                    if (!serviceTypeId || !memberId) {
                      return
                    }
                    const path = `/user-portal/${serviceTypeId}/${memberId}`
                    navigate(path)
                  }}>
                  <FilterRow showOperationChooser={false} visible={true} />
                  <Selection mode="single" />
                  <Column
                    dataField="serviceTypeDescription"
                    alignment="left"
                    caption="Service"
                  />
                  <Column dataField="firstName" caption="First Name" />
                  <Column dataField="lastName" caption="Last Name" />
                  <Column
                    caption="Activity"
                    dataField="lastUpdatedDate"
                    cellRender={dateRender}
                    defaultSortOrder="desc"
                  />
                  <Column
                    caption="Status"
                    alignment="left"
                    dataField="memberStatusDescription"
                  />
                </DataGrid>
              </GroupItem>
              {!isAgentOrLender(user?.userTypeId) && (
                <GroupItem caption="Open Tasks">
                  <DataGrid
                    dataSource={dataSource && dataSource.entity}
                    remoteOperations={{
                      paging: true,
                      sorting: true,
                      filtering: true
                    }}
                    hoverStateEnabled={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    allowColumnReordering={true}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    height="100%"
                    onCellClick={(value) => {
                      const serviceTypeId = value.data.serviceTypeId
                      const memberId = value.data.memberId
                      if (!serviceTypeId || !memberId) {
                        return
                      }
                      const path = `/user-portal/${serviceTypeId}/${memberId}`
                      navigate(path)
                    }}>
                    {colums.map((column: any, index: number) => {
                      return (
                        <Column
                          key={index}
                          dataField={column.field}
                          caption={column.name}
                          cellRender={column.render}
                          width={column.width}
                          alignment={column.alignment}
                          defaultSortOrder={column.defaultSortOrder || false}
                        />
                      )
                    })}
                  </DataGrid>
                </GroupItem>
              )}
            </GroupItem>
          </Form>
        </div>
      ) : (
        <AdminDashboard />
      )}
    </>
  )
}

export default Home
