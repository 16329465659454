import {FC, useEffect, useRef} from 'react'
import {createStore} from 'devextreme-aspnet-data-nojquery'
import {PageContainer} from 'shared/components/layout'
import {useAuth} from 'contexts/auth'
import {MemberDataGrid} from 'features/consumers/components/member-data-grid'
import useFormDataHandlerContext from '../../../shared/hooks/use-form-data-handler-context'
import {apiConsumerUrl, apiDomain} from 'api/queries'

const ADD_MEMBER_CONSUMER_URI = '/journey/Q1'

export const UserPortalMembersAll: FC = () => {
  const { setCurrentFormData } = useFormDataHandlerContext()

  useEffect(() => {
    const navigationCb = () => {
      window.open(
        apiConsumerUrl + '?consumerUri=' + ADD_MEMBER_CONSUMER_URI,
        '_blank'
      )
    }
    setCurrentFormData({ isListPage: false, navigationCb })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const authToken = sessionStorage.getItem('authToken')

  const { user } = useAuth()
  const { current: dataSourceRef } = useRef(
    createStore({
      key: 'memberId',
      loadUrl:
        apiDomain +
        '/Api/Admin/Member/DataSource' +
        `?CallerId=${user?.userId}&CallerTypeId=${user?.userTypeId}`,
      onBeforeSend: (_, ajaxSettings) => {
        ajaxSettings.headers = {
          Authorization: `Bearer ${authToken}`
        }
      }
    })
  )

  return (
    <PageContainer>
      <MemberDataGrid
        showCoBorrower={true}
        title="All Members"
        dataSource={dataSourceRef}
        isFullPath
      />
    </PageContainer>
  )
}
