import React, {useCallback, useEffect, useMemo, useRef} from 'react'
import TreeView from 'devextreme-react/tree-view'
import {navigation} from '../../app-navigation'
import {useNavigation} from '../../contexts/navigation'
import {useScreenSize} from '../../utils/media-query'
import './SideNavigationMenu.scss'

import * as events from 'devextreme/events'
import {SideNavigationMenuProps} from '../../types'
import {useAuth} from '../../contexts/auth'

export default function SideNavigationMenu(
  props: React.PropsWithChildren<SideNavigationMenuProps>
) {
  const { children, selectedItemChanged, openMenu, compactMode, onMenuReady } =
    props

  const { isLarge } = useScreenSize()
  function normalizePath() {
    return navigation.map((item) => ({
      ...item,
      expanded: isLarge,
      path: item.path && !/^\//.test(item.path) ? `/${item.path}` : item.path
    }))
  }

  const items = useMemo(
    normalizePath,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { user } = useAuth()

  const {
    navigationData: { currentPath }
  } = useNavigation()

  const treeViewRef = useRef<TreeView>(null)
  const wrapperRef = useRef<HTMLDivElement>()
  const getWrapperRef = useCallback(
    (element: HTMLDivElement) => {
      const prevElement = wrapperRef.current
      if (prevElement) {
        events.off(prevElement, 'dxclick')
      }

      wrapperRef.current = element
      events.on(element, 'dxclick', (e: React.PointerEvent) => {
        openMenu(e)
      })
    },
    [openMenu]
  )

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance
    if (!treeView) {
      return
    }

    if (currentPath !== undefined) {
      const selectExistedMenuItem = (path: string): string => {
        while (!treeView.selectItem(path)) {
          const lastSlashIndex = path.lastIndexOf('/')
          if (lastSlashIndex === -1 && !path) {
            break
          }
          path = path.substring(0, lastSlashIndex)
        }
        return path
      }

      const selectedPath = selectExistedMenuItem(currentPath)
      treeView.expandItem(selectedPath)
    }

    if (compactMode) {
      treeView.collapseAll()
    }
  }, [currentPath, compactMode])

  const itemsWithPermisssions = items.filter((item) => {
    if (item.items) {
      item.items = item.items.filter((itemSecond: any) => {
        return user && itemSecond.visibleHandler ? itemSecond.visibleHandler(user.permissionLevel) : itemSecond
      })
    }
    return user && item.visibleHandler ? item.visibleHandler(user.permissionLevel) : item
  })

  return (
    <div
      className={'dx-swatch-additional side-navigation-menu'}
      ref={getWrapperRef}>
      {children}
      <div className={'menu-container'}>
        <TreeView
          ref={treeViewRef}
          items={itemsWithPermisssions}
          keyExpr={'path'}
          selectionMode={'single'}
          focusStateEnabled={false}
          expandEvent={'click'}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={'100%'}
        />
      </div>
    </div>
  )
}
