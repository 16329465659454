import type { FC } from 'react'
import { useHubConnection } from 'shared/hooks/use-hub-connection'
import { MessageToast } from './message-toast'

export const MemberRegisterToast: FC = () => {
  const hubConnection = useHubConnection()

  if (!hubConnection) {
    return null
  }

  const { memberRegisterHubMessage, setMemberRegisterHubMessage } =
    hubConnection

  return (
    <MessageToast
      visible={!!memberRegisterHubMessage}
      message={memberRegisterHubMessage}
      onHiding={() => setMemberRegisterHubMessage('')}
    />
  )
}
