import {AccountRouteParam, UserTypeId} from 'shared/enums'

export const accountRouteParam = (userTypeId: number): string => {
  switch (userTypeId) {
    case UserTypeId.System:
    case UserTypeId.Executive:
    case UserTypeId.Administrator:
    case UserTypeId.Accounting:
    case UserTypeId.Manager:
    case UserTypeId.Advisor:
      return AccountRouteParam.HqStaff
    case UserTypeId.Lender:
      return AccountRouteParam.Lenders
    case UserTypeId.Agent:
      return AccountRouteParam.Agents
    default:
      console.error(
        "Can't get the right account route param by userTypeId: ",
        userTypeId
      )
      return ''
  }
}
