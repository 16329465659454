import {AccountRouteParam} from 'shared/enums'
import {isAdministratorPermission, isHqStaffPermission} from 'shared/functions'

export const navigation = [
  {
    text: 'Dashboard',
    path: '/dashboard',
    icon: 'home'
  },
  {
    text: 'Reports',
    icon: 'orderedlist',
    visibleHandler: isAdministratorPermission,
    items: [
      {
        text: 'Transactions',
        path: '/reports/transactions'
      },
      {
        text: 'Transactions by Service',
        path: '/reports/transactions-by-service'
      },
      {
        text: 'HQ Staff',
        path: `/reports/${AccountRouteParam.HqStaff}`
      },
      {
        text: 'Lenders',
        path: `/reports/${AccountRouteParam.Lenders}`
      },
      {
        text: 'Agents',
        path: `/reports/${AccountRouteParam.Agents}`
      }
    ]
  },
  {
    text: 'Accounts',
    icon: 'card',
    visibleHandler: isAdministratorPermission,
    items: [
      {
        text: 'HQ Staff',
        path: `/accounts/${AccountRouteParam.HqStaff}`
      },
      {
        text: 'Lenders',
        path: `/accounts/${AccountRouteParam.Lenders}`
      },
      {
        text: 'Agents',
        path: `/accounts/${AccountRouteParam.Agents}`
      }
    ]
  },
  {
    text: 'Administration',
    icon: 'preferences',
    visibleHandler: isAdministratorPermission,
    items: [
      {
        text: 'State Data',
        path: '/state-data'
      },
      {
        text: 'Global Data',
        path: '/global-data'
      },
      {
        text: 'Sources',
        path: '/sources'
      }
    ]
  },
  {
    text: 'Consumers',
    icon: 'user',
    items: [
      {
        text: 'All',
        path: '/user-portal',
        visibleHandler: isHqStaffPermission
      },
      {
        text: 'Members',
        path: '/user-portal/1'
      },
      {
        text: 'Refinance',
        path: '/user-portal/3',
        visibleHandler: isHqStaffPermission
      },
      {
        text: 'Agent Request',
        path: '/user-portal/7',
        visibleHandler: isHqStaffPermission
      },
      {
        text: 'Lender Request',
        path: '/user-portal/6',
        visibleHandler: isHqStaffPermission
      },
      {
        text: 'Buy Home Request',
        path: '/user-portal/5',
        visibleHandler: isHqStaffPermission
      },
      {
        text: 'Advisor Request',
        path: '/user-portal/8',
        visibleHandler: isHqStaffPermission
      }
    ]
  }
]
