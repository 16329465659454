import axios from 'axios'
import {GlobalSettings, IAdvisor, IAdvisorInsert, IAgent, IAgentInsert, ILender, ILenderInsert} from 'types'

export const apiDomain =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_SERVER_API_URL
    : ''
const apiUrl = `${apiDomain}/Api/Admin`
const apiChatUrl = `${apiDomain}/Api/Acs/Chat`

export const apiConsumerUrl = `${apiUrl}/Auth/ConsumerUrl`

export const userHubDomain =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_SERVER_API_URL + process.env.REACT_APP_USER_HUB_URI
    : '/UserHub'

export const advisorHubDomain =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_SERVER_API_URL +
      process.env.REACT_APP_ADVISOR_HUB_URI
    : '/AdvisorHub'

export const getAuthToken = () => {
  return sessionStorage.getItem('authToken') || undefined
}

interface ILoginQuery {
  email: string
  password: string
}

export const loginQuery = (params: ILoginQuery) => {
  return axios.post<string>(`${apiUrl}/Auth/Login`, params)
}

export const getUserQuery = () => {
  return axios.get<string>(`${apiUrl}/Auth/User`)
}

export const globalSettingsQuery = (params: GlobalSettings) => {
  return axios.put<string>(`${apiUrl}/SystemGlobal`, params)
}

export const agentUpdateQuery = (params: IAgent) => {
  return axios.put<string>(`${apiUrl}/Agent/3/${params.agentId}`, params)
}

export const agentInsertQuery = (params: IAgentInsert) => {
  return axios.post<string>(`${apiUrl}/Agent`, params)
}

export const advisorUpdateQuery = (params: IAdvisor) => {
  return axios.put<string>(
    `${apiUrl}/HomeQualStaff/11/${params.homeQualStaffId}`,
    params
  )
}

export const advisorInsertQuery = (params: IAdvisorInsert) => {
  return axios.post<string>(`${apiUrl}/HomeQualStaff`, params)
}

export const lenderUpdateQuery = (params: ILender) => {
  return axios.put<string>(`${apiUrl}/Lender/4/${params.lenderId}`, params)
}

export const lenderInsertQuery = (params: ILenderInsert) => {
  return axios.post<string>(`${apiUrl}/Lender`, params)
}

export interface ITerritory {
  stateId: string
  isActiveFlag: boolean
  allRecordsFlag: boolean
  callerId: number
  callerTypeId: number
}

export const advisorPatchTerritoryQuery = (
  homeQualStaffId: string,
  params: ITerritory
) => {
  return axios.patch<string>(
    `${apiUrl}/HomeQualStaff/Territory/11/${homeQualStaffId}`,
    params
  )
}

export const lenderPatchTerritoryQuery = (
  lenderId: string,
  params: ITerritory
) => {
  return axios.patch<string>(`${apiUrl}/Lender/Territory/4/${lenderId}`, params)
}

export const agentPatchTerritoryQuery = (
  agentId: string,
  params: ITerritory
) => {
  return axios.patch<string>(`${apiUrl}/Agent/Territory/3/${agentId}`, params)
}

export const getFile = (file: string) => {
  return axios.get<string>(`${apiUrl}/File?fileName=${file}`)
}

export interface IMember {
  memberId: number
  userTypeId: number
  permissionLevel: number
  isActiveFlag: boolean
  nameTitleId: string
  firstName: string
  lastName: string
  address: string
  city: string
  stateId: string
  zipCode: string
  memberAge: number
  emailAddress: string
  cellPhone: number
  textOptInFlag: true
  languageId: number
  communicationPreferenceId: number
  communicationOptOutStatusId: number
  coRelationshipId: number
  coNameTitleId: string
  coFirstName: string
  coLastName: string
  coAddress: string
  coCity: string
  coStateId: string
  coZipCode: string
  coEmailAddress: string
  coCellPhone: number
  coCommunicationPreferenceId: number
  coCommunicationOptOutStatusId: number
  memberScore: number
  sourceId: number
  homeQualStaffId: number
  homeQualStaffAssignedDate: string
  lenderId: number
  lenderAssignedDate: string
  lenderChange: string
  agentId: number
  agentAssignedDate: string
  agentChange: string
  revenue: number
  closeDate: string
  rebateAmount: number
  propertyValue: number
  fundedDate: string
  lastIpAddress: string
  verseActivatedFlag: true
  memberStatusId: number
}

export const memberUpdateQuery = (
  params: IMember,
  callerId: number,
  callerTypeId: number
) => {
  return axios.put<string>(
    `${apiUrl}/Member?CallerId=${callerId}&CallerTypeId=${callerTypeId}`,
    params
  )
}

export interface IMemberRequest {
  firstName: string
  lastName: string
  emailAddress: string
  cellPhone: number
  callerId: string
  CallerTypeId: string
}

export const memberInsertQuery = (
  params: IMemberRequest,
  serviceTypeId: string
) => {
  return axios.post<string>(`${apiUrl}/Member/${serviceTypeId}`, params)
}

export interface IMemberNoteInsert {
  callerId: number
  callerTypeId: number
  memberId: string
  globalDisplayFlag: boolean
  isActiveFlag: boolean
  note: string
}

export const memberNoteInsertQuery = (params: IMemberNoteInsert) => {
  return axios.post<string>(`${apiUrl}/MemberNote`, params)
}

export interface IMemberTaskInsert {
  callerId: number
  callerTypeId: number
  memberId: string
  taskPriorityId: number
  taskStatusId: number
  globalDisplayFlag: boolean
  isActiveFlag: boolean
  task?: string
  assignedToUserId: number
  assignedToUserTypeId: number
  dueDate: string
  completedDate?: string
  memberTaskId?: number
}

export const memberTaskInsertQuery = (params: IMemberTaskInsert) => {
  return axios.post<string>(`${apiUrl}/MemberTask`, params)
}

export const memberTaskPatchQuery = (params: IMemberTaskInsert) => {
  return axios.patch<string>(`${apiUrl}/MemberTask`, params)
}

export interface IAgentResetPasswordQuery {
  agentId: number
  userTypeId: number
  emailAddress: string
  password: string
}

export const agentResetPasswordQuery = (params: IAgentResetPasswordQuery) => {
  return axios.patch<string>(`${apiUrl}/Agent/Password`, params)
}

export interface IHQStaffResetPasswordQuery {
  homeQualStaffId: number
  userTypeId: number
  emailAddress: string
  password: string
}

export const hqStaffResetPasswordQuery = (
  params: IHQStaffResetPasswordQuery
) => {
  return axios.patch<string>(`${apiUrl}/HomeQualStaff/Password`, params)
}

export interface ILenderResetPasswordQuery {
  lenderId: number
  userTypeId: number
  emailAddress: string
  password: string
}

export const lenderResetPasswordQuery = (params: ILenderResetPasswordQuery) => {
  return axios.patch<string>(`${apiUrl}/Lender/Password`, params)
}

export interface IMemberResetPasswordQuery {
  memberId: number
  userTypeId: number
  serviceTypeId: number
  emailAddress: string
  password: string
}

export const memberResetPasswordQuery = (params: IMemberResetPasswordQuery) => {
  return axios.patch<string>(`${apiUrl}/Member/Password`, params)
}

export const getChatData = (
  memberId: string,
  homeQualStaffId: number,
  isHistory?: boolean
) => {
  return axios.get(
    `${apiChatUrl}/HomeQualStaff/${homeQualStaffId}/${memberId}?isHistory=${!!isHistory}`
  )
}

export const getChatVisitorData = (
  visitorIdentityId: string,
  homeQualStaffId: number,
  threadId: string
) => {
  return axios.get(
    `${apiChatUrl}/HomeQualStaff/${homeQualStaffId}/Visitor?visitorIdentityId=${visitorIdentityId}&threadId=${threadId}`
  )
}

export const chatCloseQuery = (identityId: string, threadId: string) => {
  return axios.post(`${apiChatUrl}/Closing/Advisor/${threadId}/${identityId}`)
}

export interface IVerseOnQuery {
  firstName: string
  lastName: string
  emailAddress: string
  cellPhone: number
  memberId: number
}

export const verseOnQuery = (params: IVerseOnQuery) => {
  return axios.post(`${apiUrl}/Verse/Lead`, params)
}

export const verseOffQuery = (emailAddress: string) => {
  return axios.post(`${apiUrl}/Verse/StopConversation`, {
    emailAddress: emailAddress
  })
}

export const chatLimitQuery = (memberId: string, memberName: string) => {
  return axios.post(
    `${apiChatUrl}/StartNotification/Member/${memberId}/${memberName}`
  )
}

export const passwordRecovery = (email: string) => {
  return axios.post(`${apiUrl}/Auth/Password/Recovery?email=${email}`)
}
