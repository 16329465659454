import axios, {AxiosError, AxiosInstance, AxiosResponse} from 'axios'
import {useMemo} from 'react'
import useSWR, {Key, SWRConfiguration} from 'swr'

const API_PREFIX = '/Api/Admin'

const createPublicTransport = (serverUrl: string, apiPrefix = API_PREFIX) =>
  axios.create({ baseURL: serverUrl + apiPrefix, withCredentials: true })

export const useTransport = (serverUrl: string, apiPrefix = API_PREFIX) => {
  return useMemo(() => {
    const instance = createPublicTransport(serverUrl, apiPrefix)

    // add auth header here

    return instance
  }, [serverUrl, apiPrefix])
}

export const useTransportSWR = <Data = any, Error = any>(
  transport: AxiosInstance,
  path: Key,
  config?: SWRConfiguration
) => {
  const defaultConfig = {
    shouldRetryOnError: false,
    revalidateOnFocus: false
  }

  config = {
    ...defaultConfig,
    ...config
  }

  const {
    data: response,
    error,
    isValidating,
    mutate
  } = useSWR<AxiosResponse<Data>, AxiosError<Error>>(
    path,
    (p) => transport.get<Data>(p),
    config
  )

  return {
    data: response && response.data,
    response,
    error,
    isValidating,
    mutate
  }
}
