import { memo, useEffect, useMemo, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import Form, {
  EmailRule,
  GroupItem,
  RequiredRule,
  SimpleItem
} from 'devextreme-react/form'
import {
  useTransport,
  useTransportSWR
} from '../../../shared/hooks/use-transport'
import useFormDataHandlerContext from '../../../shared/hooks/use-form-data-handler-context'
import {
  advisorInsertQuery,
  advisorPatchTerritoryQuery,
  advisorUpdateQuery,
  hqStaffResetPasswordQuery
} from '../../../api/queries'
import { useAuth } from '../../../contexts/auth'
import { LoadPanel } from 'devextreme-react/load-panel'
import { Territories } from './territories'
import { ImageUploader } from './image-uploader'
import { useSWRConfig } from 'swr'
import { ResetPassword } from './reset-password'
import { AccountRouteParam } from 'shared/enums'
import { apiDomain } from 'api/queries'
import { isHqStaffPermissionLessThan70 } from '../../../shared/functions'
import { toast } from 'react-toastify'

const ListContainer = styled.div`
  margin: 40px 40px 60px;
`

export const AccountAdvisorsDetail = memo(() => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { user } = useAuth()

  const transport = useTransport(apiDomain)

  const { data: advisor } = useTransportSWR<any>(
    transport,
    `/HomeQualStaff/11/${id}` +
      `?CallerId=${user?.userId}&CallerTypeId=${user?.userTypeId}`
  )

  const { data: territory } = useTransportSWR<any>(
    transport,
    `/HomeQualStaff/Territory/11/${id}` +
      `?CallerId=${user?.userId}&CallerTypeId=${user?.userTypeId}`
  )

  const { currentFormData, setCurrentFormData } = useFormDataHandlerContext()

  const advisorInitial = Object.assign({}, advisor)

  const formSaveHandler = (data: any) => {
    if (!data) {
      return
    }
    advisorUpdateQuery(data)
      .then((response: any) => {
        console.log(response)
      })
      .catch((err: any) => {
        console.error(err)
        toast.error(
          'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
        )
      })
  }

  const formSaveNewHandler = (data: any) => {
    if (!data) {
      return
    }
    advisorInsertQuery(data)
      .then((response: any) => {
        if (response.data.entity) {
          navigate(
            `/accounts/${AccountRouteParam.HqStaff}/${response.data.entity.homeQualStaffId}`
          )
        } else if (response.data.error) {
          toast.error(
            response.data.error?.uiDisplayMessage ||
              'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
          )
        }
        console.log(response)
      })
      .catch((err: any) => {
        console.error(err)
        toast.error(
          'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
        )
      })
  }

  const advisorInitialData = {
    bilingualFlag: false,
    isActiveFlag: false,
    userTypeId: 11,
    firstName: null,
    lastName: null,
    address: null,
    city: null,
    stateId: null,
    zipCode: null,
    emailAddress: null,
    phone: null,
    phoneExtension: null,
    phoneForSms: null,
    permissionLevel: null,
    imageFile: null,
    memberLastAssignedOnDate: null,
    callerId: user?.userId,
    callerTypeId: user?.userTypeId
  }

  const { mutate } = useSWRConfig()

  const territoryPatch = (territories: any) => {
    if (!id) {
      return
    }

    territories.map((territory: any, index: number) => {
      advisorPatchTerritoryQuery(id, territory).then(() => {
        if (index === territories.length - 1) {
          mutate(`/HomeQualStaff/Territory/11/${id}`)
        }
      })
    })
  }

  useEffect(() => {
    const currentFormDataNew = Object.assign({}, currentFormData)
    currentFormDataNew.isListPage = false
    currentFormDataNew.territories = []
    currentFormData.imageFile = false
    currentFormDataNew.territorySave = territoryPatch
    currentFormDataNew.addUrl = false
    currentFormDataNew.isFormPage = true
    currentFormDataNew.formSaveHandler =
      advisor && advisor.entity ? formSaveHandler : formSaveNewHandler
    setCurrentFormData(currentFormDataNew)
  }, [advisor])

  const formRef = useRef<any>()

  const formDataNew = useMemo(() => {
    const currentFormDataNew = Object.assign({}, currentFormData)
    return advisor && advisor.entity
      ? advisor.entity
      : currentFormDataNew.formDataNew
      ? currentFormDataNew.formDataNew
      : advisorInitialData
  }, [advisor])

  const { data: permissions } = useTransportSWR<any>(
    transport,
    `ReferenceUserType/PermissionLevels?CallerId=${user?.userId}&CallerTypeId=${user?.userTypeId}`
  )

  let permissionsDescription = []
  let currentPermission = []
  if (permissions && user) {
    permissionsDescription = permissions.entity.map((permission: any) => {
      return user!.userTypeId >= permission.userTypeId
        ? permission.description
        : false
    })
    if (formDataNew.userTypeId) {
      currentPermission = permissions.entity.filter((permission: any) => {
        return permission.userTypeId === formDataNew.userTypeId
      })
    }
  }

  const onFieldDataChangedHandler = (data: any) => {
    if (!formDataNew || !data.value === null || data.value === undefined) {
      return
    }
    const currentFormDataNew = Object.assign({}, currentFormData)
    currentFormDataNew.isFormDataChanged = true
    currentFormDataNew.formRef = formRef
    currentFormDataNew.formData = advisorInitial
    formDataNew[data.dataField] = data.value
    if (data.dataField === 'Phone') {
      formDataNew.phone = data.value || null
      formDataNew.Phone = data.value || null
    }

    if (data.dataField === 'extension') {
      formDataNew.phoneExtension = +data.value !== 0 ? data.value : null
      formDataNew.extension = +data.value !== 0 ? data.value : null
    }

    if (data.dataField === 'bilingual') {
      formDataNew.bilingualFlag = data.value || false
    }

    if (data.dataField === 'active') {
      formDataNew.isActiveFlag = data.value || false
    }

    if (data.dataField === 'role') {
      const currentPermissionLevel = permissions.entity.filter(
        (permission: any) => {
          return permission.description === data.value
        }
      )
      formDataNew.userTypeId = currentPermissionLevel[0]
        ? currentPermissionLevel[0].userTypeId
        : null
    }

    currentFormDataNew.formDataNew = formDataNew
    currentFormDataNew.validate = data.component
    setCurrentFormData(currentFormDataNew)
  }

  const onValueChange = (
    value: boolean,
    stateId: string,
    allRecordsFlag: boolean = false
  ) => {
    if (!user) {
      return
    }

    const params = {
      stateId: stateId,
      isActiveFlag: value,
      allRecordsFlag: allRecordsFlag,
      callerId: user?.userId,
      callerTypeId: user?.userTypeId
    }
    const currentFormDataNew = Object.assign({}, currentFormData)
    currentFormDataNew.isFormDataChanged = true
    if (allRecordsFlag) {
      currentFormDataNew.territories = [params]
      setCurrentFormData(currentFormDataNew)
      return
    }
    currentFormDataNew.territories.push(params)
    setCurrentFormData(currentFormDataNew)
  }

  const { data: imageFile } = useTransportSWR<any>(
    transport,
    `/File?fileName=${currentFormData.imageFile || formDataNew.imageFile}`
  )

  const resetPasswordHandler = (params: any) => {
    return hqStaffResetPasswordQuery(params)
  }

  const resetPasswordParams = advisor &&
    advisor.entity && {
      homeQualStaffId: advisor?.entity.homeQualStaffId,
      userTypeId: advisor?.entity.userTypeId,
      emailAddress: advisor?.entity.emailAddress,
      password: advisor?.entity.password,
      userId: user?.userId,
      permissionLevel: user?.permissionLevel,
      id: id
    }

  return (
    <>
      <ListContainer>
        {!id || (advisor && permissions) ? (
          <>
            <Form
              ref={formRef}
              labelLocation="left"
              formData={formDataNew}
              onFieldDataChanged={onFieldDataChangedHandler}
              showColonAfterLabel={false}
              showRequiredMark={false}>
              <GroupItem colCount={2}>
                <GroupItem caption="HQ Staff">
                  <SimpleItem dataField="firstName">
                    <RequiredRule message="First Name is required" />
                  </SimpleItem>
                  <SimpleItem dataField="lastName">
                    <RequiredRule message="Last Name is required" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="Phone"
                    editorOptions={{
                      value:
                        formDataNew && formDataNew.phone !== null
                          ? formDataNew.phone.toString()
                          : '',
                      mask: '(X00) 000-0000',
                      maskRules: { X: /[1-9]/ }
                    }}
                  />
                  <SimpleItem
                    editorOptions={{
                      value:
                        formDataNew && formDataNew.phoneExtension !== 0
                          ? formDataNew.phoneExtension
                          : null
                    }}
                    dataField="extension"
                  />
                  <SimpleItem dataField="emailAddress">
                    <RequiredRule message="Email is required" />
                    <EmailRule message="Email is invalid" />
                  </SimpleItem>
                  {advisor && advisor.entity && (
                    <GroupItem>
                      <ResetPassword
                        params={resetPasswordParams}
                        resetPasswordHandler={resetPasswordHandler}
                      />
                    </GroupItem>
                  )}
                </GroupItem>
                <GroupItem caption="Status">
                  <GroupItem>
                    <SimpleItem
                      dataField="active"
                      editorOptions={{
                        value: formDataNew.isActiveFlag,
                        height: '30px'
                      }}
                    />
                    <SimpleItem
                      editorOptions={{
                        value: formDataNew.bilingualFlag,
                        height: '45px'
                      }}
                      dataField="bilingual"
                    />
                    <SimpleItem
                      editorType="dxSelectBox"
                      editorOptions={{
                        value:
                          currentPermission[0] &&
                          currentPermission[0].description,
                        items: permissionsDescription
                      }}
                      dataField="role"
                    />
                    <GroupItem cssClass="user-image-container">
                      {id && +id > 0 && (
                        <ImageUploader
                          userTypeId={formDataNew.userTypeId}
                          entityId={id}
                          currentImg={
                            formDataNew.imageFile || currentFormData.imageFile
                              ? imageFile
                              : false
                          }
                        />
                      )}
                    </GroupItem>
                  </GroupItem>
                </GroupItem>
              </GroupItem>
            </Form>
            {territory &&
              territory.entity.length > 0 &&
              !isHqStaffPermissionLessThan70(user?.permissionLevel) && (
                <Territories data={territory} onValueChange={onValueChange} />
              )}
          </>
        ) : (
          <LoadPanel
            shadingColor="rgba(255,255,255,0.4)"
            visible={true}
            showIndicator={true}
            shading={true}
            showPane={true}
          />
        )}
      </ListContainer>
    </>
  )
})
