import {HubConnection, HubConnectionBuilder, HubConnectionState, IHttpConnectionOptions} from '@microsoft/signalr'
import {createContext, FC, ReactNode, useCallback, useContext, useEffect, useRef, useState} from 'react'
import {HubMethodId} from 'shared/enums'
import {MemberRegisterToast} from 'components/notifications/member-register-toast'
import {MemberCreateChatToast} from 'components/notifications/member-create-chat-toast'
import {MemberWithAdvisorCreateChatToast} from 'components/notifications/member-with-advisor-create-chat-toast'
import {VisitorCreateChatToast} from 'components/notifications/visitor-create-chat-toast'
import {ChatWithTabs} from 'components/chat/chat-with-tabs'
import {toast} from 'react-toastify'
import {CloseChatToast} from '../../components/notifications/close-chat-toast'

type HubConnectionProviderProps = {
  children?: ReactNode
  url: string
  authToken?: string
}

export const useHubConnectionProvider = ({
  url,
  authToken
}: HubConnectionProviderProps) => {
  const [memberRegisterHubMessage, setMemberRegisterHubMessage] =
    useState<string>('')
  const [memberCreateChatHubMessage, setMemberCreateChatHubMessage] =
    useState<any>('')
  const [memberWithAdvisorCreateChatHubMessage, setMemberWithAdvisorCreateChatHubMessage] =
    useState<any>('')
  const [visitorCreateChatHubMessage, setVisitorCreateChatHubMessage] =
    useState<any>('')
  const [closeChatHubMessage, setCloseChatHubMessage] = useState<any>('')

  const options: IHttpConnectionOptions = {
    accessTokenFactory: () => `${authToken}`
  }

  const initializeHubConnection = () => {
    return authToken
      ? new HubConnectionBuilder()
          .withUrl(url, options)
          .withAutomaticReconnect()
          .build()
      : null
  }

  const { current: hubConnectionRef } = useRef<HubConnection>(
    initializeHubConnection()
  )

  const logConnectionState = useCallback(() => {
    if (!hubConnectionRef) {
      return
    }
  }, [hubConnectionRef])

  useEffect(() => {
    if (!hubConnectionRef) {
      return
    }

    hubConnectionRef.off(HubMethodId.RegisterMember)
    hubConnectionRef.on(HubMethodId.RegisterMember, (message: string) => {
      setMemberRegisterHubMessage(message)
    })

    hubConnectionRef.off(HubMethodId.MemberCreateChat)
    hubConnectionRef.on(HubMethodId.MemberCreateChat, (message: any) => {
      setMemberCreateChatHubMessage(message)
    })

    hubConnectionRef.off(HubMethodId.MemberWithAdvisorCreateChat)
    hubConnectionRef.on(HubMethodId.MemberWithAdvisorCreateChat, (message: any) => {
      setMemberWithAdvisorCreateChatHubMessage(message)
    })

    hubConnectionRef.off(HubMethodId.VisitorCreateChat)
    hubConnectionRef.on(HubMethodId.VisitorCreateChat, (message: any) => {
      setVisitorCreateChatHubMessage(message)
    })

    hubConnectionRef.off(HubMethodId.CloseChat)
    hubConnectionRef.on(HubMethodId.CloseChat, (message: any) => {
      setCloseChatHubMessage(message)
    })

    const startConnection = () =>
      hubConnectionRef.start().then(logConnectionState)

    if (hubConnectionRef.state === HubConnectionState.Disconnected) {
      startConnection().catch((error) => {
        console.error(error)
        toast.error(
          'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
        )
        startConnection()
      })
    }

    return () => {
      hubConnectionRef.stop().then(logConnectionState)
    }
  }, [hubConnectionRef, logConnectionState])

  return {
    hubConnection: hubConnectionRef,
    memberRegisterHubMessage,
    setMemberRegisterHubMessage,
    memberCreateChatHubMessage,
    setMemberCreateChatHubMessage,
    memberWithAdvisorCreateChatHubMessage,
    setMemberWithAdvisorCreateChatHubMessage,
    visitorCreateChatHubMessage,
    setVisitorCreateChatHubMessage,
    closeChatHubMessage,
    setCloseChatHubMessage
  }
}

type HubConnectionContextProps = ReturnType<typeof useHubConnectionProvider>

const HubConnectionContext = createContext<HubConnectionContextProps | null>(
  null
)

export const useHubConnection = () => useContext(HubConnectionContext)

export const HubConnectionProvider: FC<HubConnectionProviderProps> = ({
  children,
  url,
  authToken
}) => {
  const hubConnectionProvider = useHubConnectionProvider({
    url,
    authToken
  })
  const [chatVisible, setChatVisible] = useState(false)
  const [message, setMessage] = useState<any>(false)
  const [tabs, setTabs] = useState<any>([])
  const [chatData, setChatData] = useState<any>('')

  return (
    <HubConnectionContext.Provider value={hubConnectionProvider}>
      <MemberRegisterToast />
      <CloseChatToast
        setChatVisible={setChatVisible}
        setChatData={setChatData}
        tabs={tabs}
        setTabs={setTabs}
        setMessage={setMessage}
      />
      <MemberCreateChatToast
        tabs={tabs}
        setChatVisible={setChatVisible}
        setTabs={setTabs}
        setMessage={setMessage}
        setChatData={setChatData}
      />
      <MemberWithAdvisorCreateChatToast
        tabs={tabs}
        setChatVisible={setChatVisible}
        setTabs={setTabs}
        setMessage={setMessage}
        setChatData={setChatData}
      />
      <VisitorCreateChatToast
        tabs={tabs}
        setChatVisible={setChatVisible}
        setTabs={setTabs}
        setMessage={setMessage}
        setChatData={setChatData}
      />
      {
        <ChatWithTabs
          chatVisible={chatVisible}
          setChatVisible={setChatVisible}
          message={message}
          setMessage={setMessage}
          tabs={tabs}
          setTabs={setTabs}
          chatData={chatData}
          setChatData={setChatData}
        />
      }
      {children}
    </HubConnectionContext.Provider>
  )
}
