import {useEffect, useRef} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import styled from '@emotion/styled'
import Form, {EmailRule, GroupItem, RequiredRule, SimpleItem} from 'devextreme-react/form'
import 'devextreme-react/text-area'
import {useTransport, useTransportSWR} from '../../../shared/hooks/use-transport'
import {agentInsertQuery, agentPatchTerritoryQuery, agentUpdateQuery} from '../../../api/queries'
import useFormDataHandlerContext from '../../../shared/hooks/use-form-data-handler-context'
import {useAuth} from '../../../contexts/auth'
import {Territories} from './territories'
import {ImageUploader} from './image-uploader'
import {useSWRConfig} from 'swr'
import {LoadPanel} from 'devextreme-react/load-panel'
import {ResetPassword} from './reset-password'
import {AccountRouteParam} from 'shared/enums'
import {agentResetPasswordQuery, apiDomain} from 'api/queries'
import {UserTypeId} from '../../../shared/enums/index'
import {isHqStaffPermissionLessThan70} from '../../../shared/functions'
import {toast} from 'react-toastify'

const ListContainer = styled.div`
  margin: 40px 40px 60px;
`

export const AccountAgentsDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const transport = useTransport(apiDomain)

  const { data: agent } = useTransportSWR<any>(transport, `/Agent/3/${id}`)

  const { data: territory } = useTransportSWR<any>(
    transport,
    `/Agent/Territory/3/${id}`
  )

  const { currentFormData, setCurrentFormData } = useFormDataHandlerContext()
  const agentInitial = Object.assign({}, agent)

  const formSaveHandler = (data: any) => {
    if (!data) {
      return
    }
    agentUpdateQuery(data)
      .then((response: any) => {
        console.log(response)
      })
      .catch((err: any) => {
        console.error(err)
        toast.error(
          'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
        )
      })
  }

  const formSaveNewHandler = (data: any) => {
    if (!data) {
      return
    }
    agentInsertQuery(data)
      .then((response: any) => {
        if (response.data.entity) {
          navigate(
            `/accounts/${AccountRouteParam.Agents}/${response.data.entity.agentId}`
          )
        } else if (response.data.error) {
          toast.error(
            response.data.error?.uiDisplayMessage ||
            'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
          )
        }
        console.log(response)
      })
      .catch((err: any) => {
        console.error(err)
        toast.error(
          'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
        )
      })
  }

  const { user } = useAuth()
  const agentInitialData = {
    bilingualFlag: false,
    isActiveFlag: false,
    firstName: null,
    lastName: null,
    address: null,
    city: null,
    stateId: null,
    zipCode: null,
    emailAddress: null,
    phone: null,
    phoneExtension: null,
    phoneForSms: null,
    agreementSignedDate: null,
    imageFile: null,
    mlsNumber: null,
    mlsExpiryDate: null,
    brokerName: null,
    brokerContact: null,
    brokerEmailAddress: null,
    brokerPhone: null,
    comments: null,
    apiString: null,
    callerId: user?.userId,
    callerTypeId: user?.userTypeId
  }

  const { mutate } = useSWRConfig()

  const territoryPatch = (territories: any) => {
    if (!id) {
      return
    }

    territories.map((territory: any, index: number) => {
      agentPatchTerritoryQuery(id, territory).then(() => {
        if (index === territories.length - 1) {
          mutate(`/Agent/Territory/3/${id}`)
        }
      })
    })
  }

  useEffect(() => {
    const currentFormDataNew = Object.assign({}, currentFormData)
    currentFormDataNew.isListPage = false
    currentFormData.imageFile = false
    currentFormDataNew.territories = []
    currentFormDataNew.territorySave = territoryPatch
    currentFormDataNew.addUrl = false
    currentFormDataNew.isFormPage = true
    currentFormDataNew.formSaveHandler =
      agent && agent.entity ? formSaveHandler : formSaveNewHandler
    setCurrentFormData(currentFormDataNew)
  }, [agent])

  const formRef = useRef<any>()

  const currentFormDataNew = Object.assign({}, currentFormData)

  const formDataNew =
    agent && agent.entity
      ? agent.entity
      : currentFormDataNew.formDataNew
      ? currentFormDataNew.formDataNew
      : agentInitialData

  const { data: states } = useTransportSWR<any>(transport, `/ReferenceState`)
  let statesDescription = []
  let currentState = []
  if (states) {
    statesDescription = states.states.map((state: any) => {
      return state.description
    })
    if (formDataNew.stateId) {
      currentState = states.states.filter((state: any) => {
        return state.stateId.toLowerCase() === formDataNew.stateId.toLowerCase()
      })
    }
  }
  const onFieldDataChangedHandler = (data: any) => {
    if (!formDataNew || !data.value === null || data.value === undefined) {
      return
    }
    const currentFormDataNew = Object.assign({}, currentFormData)
    currentFormDataNew.isFormDataChanged = true
    currentFormDataNew.formRef = formRef
    currentFormDataNew.formData = agentInitial
    formDataNew[data.dataField] = data.value
    if (data.dataField === 'Phone') {
      formDataNew.phone = data.value || null
      formDataNew.Phone = data.value || null
    }

    if (data.dataField === 'state') {
      const currentStateId = states.states.filter((state: any) => {
        return (
          state.description.toLowerCase() ===
          (data.value ? data.value.toLowerCase() : null)
        )
      })
      formDataNew.stateId = currentStateId[0] ? currentStateId[0].stateId : null
    }

    if (data.dataField === 'MLSNumber') {
      formDataNew.mlsNumber = data.value || null
      formDataNew.MLSNumber = data.value || null
    }

    if (data.dataField === 'extension') {
      formDataNew.phoneExtension = +data.value !== 0 ? data.value : null
    }

    if (data.dataField === 'bilingual') {
      formDataNew.bilingualFlag = data.value || false
    }

    if (data.dataField === 'active') {
      formDataNew.isActiveFlag = data.value || false
    }

    if (data.dataField === 'signedDate') {
      formDataNew.agreementSignedDate = +data.value !== 0 ? data.value : null
    }

    if (data.dataField === 'expires') {
      formDataNew.mlsExpiryDate = +data.value !== 0 ? data.value : null
    }

    currentFormDataNew.formDataNew = formDataNew
    currentFormDataNew.validate = data.component
    setCurrentFormData(currentFormDataNew)
  }

  const onValueChange = (
    value: boolean,
    stateId: string,
    allRecordsFlag: boolean = false
  ) => {
    if (!user) {
      return
    }

    const params = {
      stateId: stateId,
      isActiveFlag: value,
      allRecordsFlag: allRecordsFlag,
      callerId: user?.userId,
      callerTypeId: user?.userTypeId
    }
    const currentFormDataNew = Object.assign({}, currentFormData)
    currentFormDataNew.isFormDataChanged = true
    if (allRecordsFlag) {
      currentFormDataNew.territories = [params]
      setCurrentFormData(currentFormDataNew)
      return
    }
    currentFormDataNew.territories.push(params)
    setCurrentFormData(currentFormDataNew)
  }

  const { data: imageFile } = useTransportSWR<any>(
    transport,
    `/File?fileName=${currentFormData.imageFile || formDataNew.imageFile}`
  )

  const resetPasswordHandler = (params: any) => {
    return agentResetPasswordQuery(params)
  }

  const resetPasswordParams = agent &&
    agent.entity && {
      agentId: agent?.entity.agentId,
      userTypeId: agent?.entity.userTypeId,
      emailAddress: agent?.entity.emailAddress,
      password: agent?.entity.password,
      userId: user?.userId,
      permissionLevel: user?.permissionLevel,
      id: id
    }

  return (
    <>
      <ListContainer>
        {!id || (agent && states) ? (
          <>
            <Form
              ref={formRef}
              labelLocation="left"
              formData={formDataNew}
              onFieldDataChanged={onFieldDataChangedHandler}
              showColonAfterLabel={false}
              showRequiredMark={false}>
              <GroupItem colCount={2}>
                <GroupItem caption="Agent">
                  <SimpleItem dataField="firstName">
                    <RequiredRule message="First Name is required" />
                  </SimpleItem>
                  <SimpleItem dataField="lastName">
                    <RequiredRule message="Last Name is required" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="Phone"
                    editorOptions={{
                      value:
                        formDataNew && formDataNew.phone !== null
                          ? formDataNew.phone.toString()
                          : '',
                      mask: '(X00) 000-0000',
                      maskRules: { X: /[1-9]/ }
                    }}
                  />
                  <SimpleItem
                    editorOptions={{
                      value:
                        formDataNew && formDataNew.phoneExtension !== 0
                          ? formDataNew.phoneExtension
                          : null
                    }}
                    dataField="extension"
                  />
                  <SimpleItem dataField="emailAddress">
                    <RequiredRule message="Email is required" />
                    <EmailRule message="Email is invalid" />
                  </SimpleItem>
                  <SimpleItem dataField="address" />
                  <SimpleItem dataField="city" />
                  <SimpleItem
                    editorType="dxSelectBox"
                    editorOptions={{
                      value: currentState[0] && currentState[0].description,
                      items: statesDescription
                    }}
                    dataField="state"
                  />
                  <SimpleItem dataField="zipCode" />
                  <SimpleItem dataField="brokerName" />
                  {agent && agent.entity && (
                    <GroupItem>
                      <ResetPassword
                        params={resetPasswordParams}
                        resetPasswordHandler={resetPasswordHandler}
                      />
                    </GroupItem>
                  )}
                </GroupItem>
                <GroupItem>
                  <GroupItem caption="Status">
                    <GroupItem>
                      <SimpleItem
                        dataField="active"
                        editorOptions={{
                          value: formDataNew.isActiveFlag,
                          height: '30px'
                        }}
                      />
                      <SimpleItem
                        editorOptions={{
                          value: formDataNew.bilingualFlag,
                          height: '45px'
                        }}
                        dataField="bilingual"
                      />
                      <SimpleItem
                        editorType="dxDateBox"
                        dataField="signedDate"
                        editorOptions={{
                          value:
                            formDataNew && formDataNew.agreementSignedDate !== 0
                              ? formDataNew.agreementSignedDate
                              : null
                        }}
                      />
                      <SimpleItem
                        label={{text: 'MLS Number'}}
                        editorOptions={{
                          value:
                            formDataNew && formDataNew.mlsNumber
                              ? formDataNew.mlsNumber
                              : null
                        }}
                        dataField="MLSNumber"
                      />
                      <SimpleItem
                        editorType="dxDateBox"
                        dataField="expires"
                        editorOptions={{
                          value:
                            formDataNew && formDataNew.mlsExpiryDate !== 0
                              ? formDataNew.mlsExpiryDate
                              : null
                        }}
                      />
                      <GroupItem cssClass="user-image-container">
                        {id && +id > 0 && (
                          <ImageUploader
                            userTypeId={UserTypeId.Agent}
                            entityId={id}
                            currentImg={
                              formDataNew.imageFile || currentFormData.imageFile
                                ? imageFile
                                : false
                            }
                          />
                        )}
                      </GroupItem>
                    </GroupItem>
                  </GroupItem>
                  <GroupItem>
                    <GroupItem>
                      <GroupItem caption="Comments" />
                      <SimpleItem
                        editorType="dxTextArea"
                        dataField="comments"
                      />
                    </GroupItem>
                  </GroupItem>
                </GroupItem>
              </GroupItem>
            </Form>
            {territory &&
              territory.entity.length > 0 &&
              !isHqStaffPermissionLessThan70(user?.permissionLevel) && (
                <Territories data={territory} onValueChange={onValueChange} />
              )}
          </>
        ) : (
          <LoadPanel
            shadingColor="rgba(255,255,255,0.4)"
            visible={true}
            showIndicator={true}
            shading={true}
            showPane={true}
          />
        )}
      </ListContainer>
    </>
  )
}
