import {FC, useEffect, useState} from 'react'
import CustomStore from 'devextreme/data/custom_store'
import {createStore} from 'devextreme-aspnet-data-nojquery'
import {useParams} from 'react-router-dom'
import {PageContainer} from 'shared/components/layout'
import {useAuth} from 'contexts/auth'
import {MemberDataGrid} from 'features/consumers/components/member-data-grid'
import useFormDataHandlerContext from '../../../shared/hooks/use-form-data-handler-context'
import {apiConsumerUrl, apiDomain} from 'api/queries'
import {isAgent, isAgentOrLender, isHqStaffPermission, isLender} from '../../../shared/functions'

export const UserPortalMembersByServiceTypeId: FC = () => {
  const { setCurrentFormData } = useFormDataHandlerContext()

  const authToken = sessionStorage.getItem('authToken')

  const [dataSource, setDataSource] = useState<CustomStore>()
  const { user } = useAuth()
  const { serviceTypeId } = useParams()
  const ADD_MEMBER_CONSUMER_URI = '/journey/Q1'

  useEffect(() => {
    let filter = ''
    if (isLender(user?.userTypeId)) {
      filter = `&filter=["lenderId","=","${user?.userId}"]`
    } else if (isAgent(user?.userTypeId)) {
      filter = `&filter=["agentId","=","${user?.userId}"]`
    }
    const storeInstance = createStore({
      key: 'memberId',
      loadUrl:
        apiDomain +
        `/Api/Admin/Member/DataSource/${serviceTypeId}` +
        `?CallerId=${user?.userId}&CallerTypeId=${user?.userTypeId}${filter}`,
      onBeforeSend: (_, ajaxSettings) => {
        ajaxSettings.headers = {
          Authorization: `Bearer ${authToken}`
        }
      }
    })
    setDataSource(storeInstance)
  }, [setDataSource, user, authToken, serviceTypeId])

  let title
  switch (serviceTypeId) {
    case '1':
      title = 'Members'
      break
    case '3':
      title = 'Refinance'
      break
    case '5':
      title = 'Buy Home Request'
      break
    case '6':
      title = 'Lender Request'
      break
    case '7':
      title = 'Agent Request'
      break
    case '8':
      title = 'Advisor Request'
      break
  }

  useEffect(() => {
    const navigationCb = () => {
      window.open(
        apiConsumerUrl + '?consumerUri=' + ADD_MEMBER_CONSUMER_URI,
        '_blank'
      )
    }
    const data =
      serviceTypeId === '1'
        ? {
            isListPage:
              isHqStaffPermission(user?.permissionLevel) ||
              isAgentOrLender(user?.userTypeId),
            navigationCb
          }
        : {
            isListPage:
              isHqStaffPermission(user?.permissionLevel) ||
              isAgentOrLender(user?.userTypeId),
            addUrl: `/user-portal/${serviceTypeId}/0`
          }
    setCurrentFormData(data)
  }, [serviceTypeId])

  if (!dataSource) {
    return null
  }

  return (
    <PageContainer>
      <MemberDataGrid
        showCoBorrower={false}
        title={title}
        dataSource={dataSource}
      />
    </PageContainer>
  )
}
