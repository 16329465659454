import {FC, useEffect} from 'react'
import {useHubConnection} from 'shared/hooks/use-hub-connection'
import Notify from 'devextreme/ui/notify'
import {isChatIdentityExist} from '../../shared/functions'

interface IMemberCreateChatToast {
  tabs: any
  setTabs: any
  setChatVisible: any
  setMessage: any
  setChatData: any
}

export const MemberCreateChatToast: FC<IMemberCreateChatToast> = ({
  tabs,
  setTabs,
  setChatVisible,
  setMessage,
  setChatData
}) => {
  const hubConnection = useHubConnection()

  const onHiding = (memberCreateChatHubMessage: any) => {
    if (!isChatIdentityExist(tabs, memberCreateChatHubMessage.memberId)) {
      tabs.push({
        memberId: memberCreateChatHubMessage.memberId,
        memberName: memberCreateChatHubMessage.memberName
      })
      setTabs(tabs)
    }
    setChatData('')
    setMessage(false)
    setChatVisible(true)
    setMessage({ memberId: memberCreateChatHubMessage.memberId })
  }

  useEffect(() => {
    if (hubConnection) {
      const { memberCreateChatHubMessage, setMemberCreateChatHubMessage } =
        hubConnection
      if (memberCreateChatHubMessage) {
        if (tabs.length > 3) {
          setMemberCreateChatHubMessage('')
          return
        }
        setMessage({ memberId: memberCreateChatHubMessage.memberId })
        Notify(
          {
            message: `Member entered into chat`,
            width: 250,
            type: 'info',
            onHiding: () => {
              onHiding(memberCreateChatHubMessage)
            },
            closeOnClick: true,
            displayTime: 86400000,
            animation: {
              show: {
                type: 'fade',
                duration: 400,
                from: 0,
                to: 1
              },
              hide: { type: 'fade', duration: 40, to: 0 }
            }
          },
          {
            position: 'top right',
            direction: 'left-stack'
          }
        )
        setMemberCreateChatHubMessage('')
      }
    }
  }, [hubConnection])

  return <></>
}
