export const phoneFormat = (s: any) => {
  const stringNumb = s.toString()
  let phone = stringNumb.replace(/[^0-9]/g, '')
  return phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/g, `($1) $2-$3$4`)
}

export const numberWithCommas = (number: number | string) => {
  if (!number) {
    return '0'
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const removeCommas = (number: number | string) => {
  if (!number) {
    return '0'
  }
  return +number.toString().replace(',', '')
}

export const formatNumber = (data: string, position: number) => {
  if (data.indexOf(',') !== -1) {
    data = data.replace(',', '.')
  }
  if (data.indexOf('.') !== -1 || !data || data.length <= position) {
    return data
  }
  const leftPath = data.substring(0, position)
  const rightPath = data.substring(position)
  return leftPath + '.' + rightPath
}

export const isHqStaffHandler = (id: number) => {
  const hqStaffIds = [11, 12, 13, 14, 15, 99]
  return hqStaffIds.indexOf(id) !== -1
}

export const isAdministratorPermission = (permissionLevel: number | undefined) => {
  if (!permissionLevel) {
    return false
  }
  return permissionLevel >= 80
}

export const isHqStaffPermission = (permissionLevel: number | undefined) => {
  if (!permissionLevel) {
    return false
  }
  return permissionLevel >= 50
}

export const isHqStaffPermissionGreaterThan60 = (permissionLevel: number | undefined) => {
  if (!permissionLevel) {
    return false
  }
  return permissionLevel >= 60
}

export const isHqStaffPermissionGreaterThan70 = (permissionLevel: number | undefined) => {
  if (!permissionLevel) {
    return false
  }
  return permissionLevel >= 70
}

export const isHqStaffPermissionLessThan70 = (permissionLevel: number | undefined) => {
  if (!permissionLevel) {
    return false
  }
  return permissionLevel < 70
}

export const isAgentOrLender = (id: number | undefined) => {
  if (!id) {
    return false
  }
  const AgentAndLenderIds = [2, 3]
  return AgentAndLenderIds.indexOf(id) !== -1
}

export const isAgent = (id: number | undefined) => {
  if (!id) {
    return false
  }
  return id === 2
}

export const isLender = (id: number | undefined) => {
  if (!id) {
    return false
  }
  return id === 3
}

export const isChatIdentityExist = (tabs: any, identity: number | string) => {
  const existIdentity = tabs.filter((item: any) => {
    return item.memberId === identity || item.visitorIdentityId === identity
  })
  return !!existIdentity.length
}


