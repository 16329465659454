import type { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import appInfo from './app-info'
import routes from './app-routes'
import { SideNavInnerToolbar as SideNavBarLayout } from './layouts'
import { HubConnectionProvider } from 'shared/hooks/use-hub-connection'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { advisorHubDomain, getAuthToken } from './api/queries'
import { useAuth } from 'contexts/auth'
import { UserTypeId } from 'shared/enums'

const AppRoutes: FC = () => {
  return (
    <Routes>
      {routes.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  )
}

export default function Content() {
  const authToken = getAuthToken()
  const { user } = useAuth()

  return (
    <SideNavBarLayout title={appInfo.title}>
      <ToastContainer />
      {user?.userTypeId === UserTypeId.Advisor ? (
        <HubConnectionProvider url={advisorHubDomain} authToken={authToken}>
          <AppRoutes />
        </HubConnectionProvider>
      ) : (
        <AppRoutes />
      )}
    </SideNavBarLayout>
  )
}
