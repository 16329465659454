import {NotesOrTasksGrid} from './notes-or-tasks-grid'
import moment from 'moment'
import {Popup} from 'devextreme-react/popup'
import React, {FC, useState} from 'react'
import Form, {GroupItem, SimpleItem} from 'devextreme-react/form'
import Button from 'devextreme-react/button'
import {useTransport, useTransportSWR} from '../../../../shared/hooks/use-transport'
import {useSWRConfig} from 'swr'
import {apiDomain, memberTaskInsertQuery, memberTaskPatchQuery} from '../../../../api/queries'
import {useAuth} from '../../../../contexts/auth'
import {isAdministratorPermission, isHqStaffPermission} from '../../../../shared/functions'
import {toast} from 'react-toastify'

export const Tasks: FC<{
  memberId: string
}> = ({ memberId }) => {
  const transport = useTransport(apiDomain)
  const { user } = useAuth()
  const dateRender = (cellData: any) => {
    return moment(cellData.value).format('MM/DD/YYYY')
  }
  const closedRender = (cellData: any) => {
    return cellData.value ? moment(cellData.value).format('MM/DD/YYYY') : 'OPEN'
  }
  const textRender = (cellData: any) => {
    return (
      <div className="textRender">
        <div className="textRenderFrom">{`Assigned To: ${cellData.data.createdByFirstName} ${cellData.data.createdByLastName}`}</div>
        <div className="textRenderText">{cellData.value}</div>
      </div>
    )
  }
  const { data: dataSource } = useTransportSWR<any>(
    transport,
    `/MemberTask/${memberId}?CallerId=${user?.userId}&CallerTypeId=${user?.userTypeId}`
  )
  const { mutate } = useSWRConfig()

  const colums = [
    {
      name: 'Due Date',
      field: 'dueDate',
      render: dateRender,
      width: '120px',
      alignment: 'center',
      defaultSortIndex: 0,
      defaultSortOrder: 'asc'
    },
    {
      name: 'Task',
      field: 'task',
      render: textRender,
      alignment: 'left'
    },
    {
      name: 'Closed',
      field: 'completedDate',
      render: closedRender,
      width: '100px',
      alignment: 'center',
      defaultSortIndex: 1,
      defaultSortOrder: 'asc'
    }
  ]

  const [isPopupVisible, setPopupVisibility] = useState(false)
  const togglePopup = () => {
    setPopupVisibility(!isPopupVisible)
  }

  const RenderContent = (props: any) => {
    const { togglePopup, formData } = props
    const [note, setNote] = useState(formData.note)
    const [dueDate, setDueDate] = useState(formData.date)
    const [closed, setClosed] = useState(!formData.closed)
    const saveHandler = () => {
      if (!user) {
        return
      }

      if (formData.exist) {
        if (closed) {
          return
        }
        const params = {
          callerId: user.userId,
          callerTypeId: user.userTypeId,
          memberId: memberId,
          memberTaskId: formData?.memberTaskId,
          globalDisplayFlag: closed,
          isActiveFlag: closed,
          taskPriorityId: 0,
          taskStatusId: 0,
          assignedToUserId: user.userId,
          assignedToUserTypeId: user.userTypeId,
          dueDate: dueDate,
          completedDate: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss')
        }
        memberTaskPatchQuery(params)
          .then((response: any) => {
            mutate(
              `/MemberTask/${memberId}?CallerId=${user?.userId}&CallerTypeId=${user?.userTypeId}`
            )
            console.log(response)
          })
          .catch((err: any) => {
            console.error(err)
            toast.error(
              'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
            )
          })
      } else {
        const params = {
          callerId: user.userId,
          callerTypeId: user.userTypeId,
          memberId: memberId,
          globalDisplayFlag: true,
          isActiveFlag: true,
          task: note,
          taskPriorityId: 0,
          taskStatusId: 0,
          assignedToUserId: user.userId,
          assignedToUserTypeId: user.userTypeId,
          dueDate: dueDate
        }

        memberTaskInsertQuery(params)
          .then((response: any) => {
            mutate(
              `/MemberTask/${memberId}?CallerId=${user?.userId}&CallerTypeId=${user?.userTypeId}`
            )
            console.log(response)
          })
          .catch((err: any) => {
            console.error(err)
            toast.error(
              'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
            )
          })
      }
    }
    const onFieldDataChangedHandler = (data: any) => {
      if (data.dataField === 'note') {
        setNote(data.value)
      }
      if (data.dataField === 'date') {
        const date = new Date(
          Date.parse(moment(data.value).format('MM/DD/YYYY')) + 97199000
        )
        setDueDate(date)
      }
      if (data.dataField === 'closed') {
        setClosed(!data.value)
      }
    }

    return (
      <>
        <Form
          labelLocation="left"
          formData={formData}
          onFieldDataChanged={onFieldDataChangedHandler}
          showColonAfterLabel={false}
          showRequiredMark={false}>
          <GroupItem colCount={2}>
            <SimpleItem
              editorType="dxDateBox"
              dataField="date"
              label={{ text: 'Due Date', location: 'top' }}
              editorOptions={{ disabled: formData.exist }}
            />
            {formData.exist && (
              <SimpleItem
                dataField="closed"
                editorOptions={{ disabled: formData.completed }}
              />
            )}
          </GroupItem>
          <GroupItem>
            <SimpleItem
              label={{ text: ' ', location: 'top' }}
              editorType="dxTextArea"
              dataField="note"
              editorOptions={{ height: '140px', disabled: formData.exist }}
            />
          </GroupItem>
        </Form>
        <div className="popup-reset-password-wrap">
          {!formData.completed &&
            ((isHqStaffPermission(user?.permissionLevel) && !formData.exist) ||
              formData.createdByUserId === user?.userId ||
              isAdministratorPermission(user?.permissionLevel)) && (
              <Button
                className={
                  'btn-hq-color user-image-button popup-reset-password'
                }
                stylingMode={'text'}
                onClick={() => {
                  saveHandler()
                  togglePopup()
                }}>
                <span className="dx-button-text">Save</span>
              </Button>
            )}
          <Button
            className={'btn-hq-color user-image-button popup-reset-password'}
            stylingMode={'text'}
            onClick={() => {
              togglePopup()
            }}>
            <span className="dx-button-text">
              {formData.completed ? 'Close' : 'Cancel'}
            </span>
          </Button>
        </div>
      </>
    )
  }

  const [formData, setFormData] = useState({
    note: '',
    date: '',
    closed: true
  })

  const renderPopup = (props: any) => {
    return <RenderContent formData={formData} togglePopup={togglePopup} />
  }

  return (
    <>
      {dataSource && dataSource.entity && (
        <>
          <Popup
            title="TASK"
            contentRender={renderPopup}
            visible={isPopupVisible}
            hideOnOutsideClick={true}
            onHiding={togglePopup}
            width={400}
            height={350}
          />
          <NotesOrTasksGrid
            dataSource={dataSource.entity}
            colums={colums}
            setFormData={setFormData}
            togglePopup={togglePopup}
            addButtonShow={true}
          />
        </>
      )}
    </>
  )
}
