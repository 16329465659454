import {FC, useEffect, useRef} from 'react'
import Form, {EmailRule, GroupItem, RequiredRule, SimpleItem} from 'devextreme-react/form'
import {useTransport, useTransportSWR} from 'shared/hooks/use-transport'
import {GlobalSettings} from 'types'
import useFormDataHandlerContext from '../../../../shared/hooks/use-form-data-handler-context'
import {globalSettingsQuery} from '../../../../api/queries'
import {LoadPanel} from 'devextreme-react/load-panel'
import {formatNumber, numberWithCommas} from '../../../../shared/functions'
import {ResetGlobalPassword} from '../../../../pages/administration/global-settings/reset-global-password'
import {apiDomain} from 'api/queries'
import {toast} from 'react-toastify'

export const GlobalSettingsForm: FC = () => {
  const transport = useTransport(apiDomain)

  const { data: globalSettings } = useTransportSWR<GlobalSettings>(
    transport,
    '/SystemGlobal'
  )

  const { currentFormData, setCurrentFormData } = useFormDataHandlerContext()
  const globalSettingsInitial = Object.assign({}, globalSettings)

  const formSaveHandler = (globalSettingsParams: any) => {
    if (!globalSettingsParams) {
      return
    }
    globalSettingsParams.FixedCosts = globalSettingsParams.FixedCosts.replace(
      ',',
      ''
    )
    globalSettingsQuery(globalSettingsParams)
      .then((response: any) => {
        console.log(response)
      })
      .catch((err: any) => {
        console.error(err)
        toast.error(
          'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
        )
      })
  }

  useEffect(() => {
    const currentFormDataNew = Object.assign({}, currentFormData)
    currentFormDataNew.isListPage = false
    currentFormDataNew.addUrl = false
    currentFormDataNew.isFormPage = true
    currentFormDataNew.formSaveHandler = formSaveHandler
    setCurrentFormData(currentFormDataNew)
  }, [globalSettings])

  const formRef = useRef<any>()

  const currentFormDataNew = Object.assign({}, currentFormData)

  const formDataNew = globalSettings
    ? globalSettings
    : currentFormDataNew.formDataNew
    ? currentFormDataNew.formDataNew
    : false

  const onFieldDataChangedHandler = (data: any) => {
    const currentFormDataNew = Object.assign({}, currentFormData)
    formDataNew[data.dataField] = data.value

    if (data.dataField === 'TollFreePhone') {
      formDataNew.tollFreePhone = data.value || null
      formDataNew.TollFreePhone = data.value || null
    }

    if (data.dataField === 'LocalPhone') {
      formDataNew.localPhone = data.value || null
      formDataNew.LocalPhone = data.value || null
    }

    if (data.dataField === 'PmiRate') {
      formDataNew.pmiRate = formatNumber(data.value, 1) || null
      formDataNew.PmiRate = formatNumber(data.value, 1) || null
    }

    if (data.dataField === 'InterestRate') {
      formDataNew.interestRate = formatNumber(data.value, 2) || null
      formDataNew.InterestRate = formatNumber(data.value, 2) || null
    }

    if (data.dataField === 'RebateLow') {
      formDataNew.rebateLow = formatNumber(data.value, 1) || null
      formDataNew.RebateLow = formatNumber(data.value, 1) || null
    }

    if (data.dataField === 'RebateHigh') {
      formDataNew.rebateHigh = formatNumber(data.value, 1) || null
      formDataNew.RebateHigh = formatNumber(data.value, 1) || null
    }

    if (data.dataField === 'FixedCosts') {
      formDataNew.fixedCosts = data.value.replace(',', '') || null
      formDataNew.FixedCosts = numberWithCommas(data.value) || null
    }

    if (data.dataField === 'state') {
      formDataNew.stateId = data.value || null
      formDataNew.state = data.value || null
    }

    if (data.dataField === 'General') {
      formDataNew.generalEmail = data.value || null
      formDataNew.General = data.value || null
    }

    if (data.dataField === 'Media') {
      formDataNew.mediaEmail = data.value || null
      formDataNew.Media = data.value || null
    }

    if (data.dataField === 'MemberServices') {
      formDataNew.memberServicesEmail = data.value || null
      formDataNew.MemberServices = data.value || null
    }

    if (data.dataField === 'TechSupport') {
      formDataNew.techSupportEmail = data.value || null
      formDataNew.TechSupport = data.value || null
    }

    currentFormDataNew.isFormDataChanged = true
    currentFormDataNew.formRef = formRef
    currentFormDataNew.formData = globalSettingsInitial
    currentFormDataNew.validate = data.component
    currentFormDataNew.formDataNew = formDataNew
    setCurrentFormData(currentFormDataNew)
  }

  return formDataNew ? (
    <Form
      ref={formRef}
      labelLocation="left"
      formData={formDataNew}
      showColonAfterLabel={false}
      showRequiredMark={false}
      onFieldDataChanged={onFieldDataChangedHandler}>
      <GroupItem caption="HomeQual Business Information" />
      <GroupItem colCount={2}>
        <GroupItem caption="Contact Information">
          <SimpleItem dataField="businessName">
            <RequiredRule message="Business Name is required" />
          </SimpleItem>
          <SimpleItem dataField="address" />
          <SimpleItem dataField="city" />
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="state"
              editorOptions={{
                value:
                  formDataNew && formDataNew.stateId
                    ? formDataNew.stateId
                    : null
              }}
            />
            <SimpleItem dataField="zipCode" />
          </GroupItem>
          <SimpleItem
            dataField="TollFreePhone"
            editorOptions={{
              value:
                formDataNew && formDataNew.tollFreePhone
                  ? formDataNew.tollFreePhone.toString()
                  : '',
              mask: '(X00) 000-0000',
              maskRules: { X: /[1-9]/ }
            }}
          />
          <SimpleItem
            dataField="LocalPhone"
            editorOptions={{
              value:
                formDataNew && formDataNew.localPhone
                  ? formDataNew.localPhone.toString()
                  : '',
              mask: '(X00) 000-0000',
              maskRules: { X: /[1-9]/ }
            }}
          />
        </GroupItem>
        <GroupItem caption="Email Contacts">
          <SimpleItem
            dataField="General"
            editorOptions={{
              value:
                formDataNew && formDataNew.generalEmail
                  ? formDataNew.generalEmail
                  : null
            }}>
            <EmailRule message="Email is invalid" />
          </SimpleItem>
          <SimpleItem
            dataField="Media"
            editorOptions={{
              value:
                formDataNew && formDataNew.mediaEmail
                  ? formDataNew.mediaEmail
                  : null
            }}>
            <EmailRule message="Email is invalid" />
          </SimpleItem>
          <SimpleItem
            dataField="MemberServices"
            editorOptions={{
              value:
                formDataNew && formDataNew.memberServicesEmail
                  ? formDataNew.memberServicesEmail
                  : null
            }}>
            <EmailRule message="Email is invalid" />
          </SimpleItem>
          <SimpleItem
            dataField="TechSupport"
            editorOptions={{
              value:
                formDataNew && formDataNew.techSupportEmail
                  ? formDataNew.techSupportEmail
                  : null
            }}>
            <EmailRule message="Email is invalid" />
          </SimpleItem>
          <SimpleItem dataField="defaultSalutation" />
        </GroupItem>
      </GroupItem>
      <GroupItem cssClass="marginTop" caption="Global Variables" />
      <GroupItem colCount={2}>
        <GroupItem>
          <SimpleItem dataField="copyright" />
          <SimpleItem dataField="abandonDays" />
          <SimpleItem dataField="timeOutHours" />
          <SimpleItem dataField="resetPasswordDays" />
          <GroupItem>
            <ResetGlobalPassword />
          </GroupItem>
        </GroupItem>
        <GroupItem colCount={2}>
          <GroupItem>
            <SimpleItem
              dataField="PmiRate"
              label={{
                text: 'PMI Rate'
              }}
              editorOptions={{
                value:
                  formDataNew && formDataNew.pmiRate
                    ? formDataNew.pmiRate.toString()
                    : ''
              }}
            />
            <SimpleItem
              dataField="InterestRate"
              editorOptions={{
                value:
                  formDataNew && formDataNew.interestRate
                    ? formDataNew.interestRate.toString()
                    : ''
              }}
            />
            <SimpleItem
              dataField="FixedCosts"
              editorOptions={{
                value:
                  formDataNew && formDataNew.fixedCosts
                    ? numberWithCommas(formDataNew.fixedCosts)
                    : ''
              }}
            />
          </GroupItem>
          <GroupItem>
            <SimpleItem
              dataField="RebateLow"
              editorOptions={{
                value:
                  formDataNew && formDataNew.rebateLow
                    ? formDataNew.rebateLow.toString()
                    : ''
              }}
            />
            <SimpleItem
              dataField="RebateHigh"
              editorOptions={{
                value:
                  formDataNew && formDataNew.rebateHigh
                    ? formDataNew.rebateHigh.toString()
                    : ''
              }}
            />
          </GroupItem>
        </GroupItem>
      </GroupItem>
    </Form>
  ) : (
    <LoadPanel
      shadingColor="rgba(255,255,255,0.4)"
      visible={true}
      showIndicator={true}
      shading={true}
      showPane={true}
    />
  )
}
