import {Popup} from 'devextreme-react/popup'
import {useState} from 'react'
import Form, {GroupItem, SimpleItem} from 'devextreme-react/form'
import {numberWithCommas} from '../../../shared/functions'
import Button from 'devextreme-react/button'
import useFormDataHandlerContext from '../../../shared/hooks/use-form-data-handler-context'

export const CloseDetails = (props: any) => {
  const { onFieldDataChangedHandler, formData, formSaveHandler } = props
  const { currentFormData } = useFormDataHandlerContext()
  const [isPopupVisible, setPopupVisibility] = useState(false)
  const togglePopup = () => {
    setPopupVisibility(!isPopupVisible)
  }

  const RenderContent = (props: any) => {
    const { togglePopup } = props
    return (
      <>
        <Form
          labelLocation="left"
          formData={formData}
          onFieldDataChanged={onFieldDataChangedHandler}>
          <GroupItem>
            <SimpleItem
              editorOptions={{ width: '80%' }}
              editorType="dxDateBox"
              dataField="closeDate"
            />
            <SimpleItem
              dataField="propertyValue"
              editorOptions={{
                value:
                  formData && formData.propertyValue
                    ? numberWithCommas(formData.propertyValue)
                    : ''
              }}
            />
            <SimpleItem
              dataField="rebateAmount"
              editorOptions={{
                value:
                  formData && formData.rebateAmount
                    ? numberWithCommas(formData.rebateAmount)
                    : ''
              }}
            />
            <SimpleItem
              editorOptions={{ width: '80%' }}
              editorType="dxDateBox"
              dataField="fundedDate"
            />
            <SimpleItem
              dataField="revenue"
              editorOptions={{
                value:
                  formData && formData.revenue
                    ? numberWithCommas(formData.revenue)
                    : ''
              }}
            />
          </GroupItem>
        </Form>
        <div className="popup-reset-password-wrap">
          <Button
            className={'btn-hq-color user-image-button popup-reset-password'}
            stylingMode={'text'}
            onClick={() => {
              formSaveHandler(currentFormData.formDataNew)
              togglePopup()
            }}>
            <span className="dx-button-text">Save</span>
          </Button>

          <Button
            className={'btn-hq-color user-image-button popup-reset-password'}
            stylingMode={'text'}
            onClick={() => {
              togglePopup()
            }}>
            <span className="dx-button-text">Cancel</span>
          </Button>
        </div>
      </>
    )
  }

  const renderPopup = (props: any) => {
    return <RenderContent togglePopup={togglePopup} />
  }

  return (
    <>
      <Button
        className={'btn-hq-color user-image-button detail-button'}
        stylingMode={'text'}
        onClick={() => {
          togglePopup()
        }}>
        <span className="dx-button-text">Close Details</span>
      </Button>
      <div className='position-absolute'>
        <Popup
          title="Close Details"
          contentRender={renderPopup}
          visible={isPopupVisible}
          hideOnOutsideClick={true}
          onHiding={togglePopup}
          width={400}
          height={400}
        />
      </div>
    </>
  )
}
