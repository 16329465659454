import React from 'react'
import ScrollView from 'devextreme-react/scroll-view'
import './single-card.scss'
import styled from '@emotion/styled'
import logoImg from '../../assets/images/hqlogolight.png'
import type { SingleCardProps } from '../../types'

const ImageHolder = styled(({ ...props }) => <div {...props} />)`
  width: 100%;
  height: 35px;
  margin-bottom: 10px;
  background: url(${(props) => props.logo}) 100% 50% no-repeat;
  background-position-x: right;
  -webkit-background-size: contain;
  background-size: contain;
`

export default function SingleCard({
  title,
  description,
  logo,
  children
}: React.PropsWithChildren<SingleCardProps>) {
  return (
    <ScrollView
      height={'100%'}
      width={'100%'}
      className={'with-footer single-card'}>
      <div className={'dx-card content'}>
        <div className={'header'}>
          {logo && <ImageHolder logo={logoImg} />}
          <div className={'title'}>{title}</div>
          <div className={'description'}>{description}</div>
        </div>
        {children}
      </div>
    </ScrollView>
  )
}
