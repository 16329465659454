import React, {useState} from 'react'
import Button from 'devextreme-react/button'
import {Popup} from 'devextreme-react/popup'

const RenderContent = (props: any) => {
  const { togglePopup, togglePopupConfirm } = props
  const [loading, setLoading] = useState(false)
  return (
    <>
      <div className="popup-reset-password-description">
        Resetting Passwords will require all users to change their password.
      </div>
      <p className="popup-reset-password-question">
        Are you sure you want to do this?
      </p>
      <div className="popup-reset-password-wrap">
        <Button
          className={'btn-hq-color user-image-button popup-reset-password'}
          stylingMode={'text'}
          onClick={() => {
            setLoading(true)
            togglePopup()
            togglePopupConfirm()
          }}>
          <span className="dx-button-text">Yes</span>
        </Button>

        <Button
          className={'btn-hq-color user-image-button popup-reset-password'}
          stylingMode={'text'}
          onClick={() => {
            togglePopup()
          }}>
          <span className="dx-button-text">No</span>
        </Button>
      </div>
    </>
  )
}

const RenderContentConfirm = (props: any) => {
  const { togglePopup } = props
  const [loading, setLoading] = useState(false)
  return (
    <>
      <div className="popup-reset-password-description">
        Click Yes to confirm password reset.
      </div>
      <p className="popup-reset-password-question"> </p>
      <div className="popup-reset-password-wrap">
        <Button
          className={'btn-hq-color user-image-button popup-reset-password'}
          stylingMode={'text'}
          onClick={() => {
            setLoading(true)
            setTimeout(() => {
              togglePopup()
            }, 500)
          }}>
          <span className="dx-button-text">Yes</span>
        </Button>

        <Button
          className={'btn-hq-color user-image-button popup-reset-password'}
          stylingMode={'text'}
          onClick={() => {
            togglePopup()
          }}>
          <span className="dx-button-text">No</span>
        </Button>
      </div>
    </>
  )
}

export const ResetGlobalPassword = () => {
  const [isPopupVisible, setPopupVisibility] = useState(false)
  const [isPopupConfirmVisible, setPopupConfirmVisibility] = useState(false)
  const togglePopup = () => {
    setPopupVisibility(!isPopupVisible)
  }
  const togglePopupConfirm = () => {
    setPopupConfirmVisibility(!isPopupConfirmVisible)
  }

  function renderPopup(props: any) {
    return (
      <RenderContent
        togglePopupConfirm={togglePopupConfirm}
        togglePopup={togglePopup}
      />
    )
  }

  function renderPopupConfirm(props: any) {
    return <RenderContentConfirm togglePopup={togglePopupConfirm} />
  }

  return (
    <>
      <Popup
        title="Reset All HQ Passwords"
        contentRender={renderPopup}
        visible={isPopupVisible}
        hideOnOutsideClick={true}
        onHiding={togglePopup}
        width={400}
        height={280}
      />
      <Popup
        title="Reset All HQ Passwords confirm"
        contentRender={renderPopupConfirm}
        visible={isPopupConfirmVisible}
        hideOnOutsideClick={true}
        onHiding={togglePopupConfirm}
        width={400}
        height={220}
      />
      <Button
        className={'btn-hq-color user-image-button reset-password'}
        stylingMode={'text'}
        onClick={() => {
          togglePopup()
          /* setLoading(true)
            toast.success('A new password has been sent to your email') */
        }}>
        <span className="dx-button-text">Reset All HQ Passwords</span>
      </Button>
    </>
  )
}
