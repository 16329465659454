import React, {FC, useEffect, useState} from 'react'
import {ChatContainer} from '../../../../components/chat/ChatContainer'
import {getChatData} from '../../../../api/queries'
import {useAuth} from '../../../../contexts/auth'

export const Chats: FC<{
  homeQualStaffId: number
  memberId: string
  className?: string
}> = ({ homeQualStaffId, memberId, className }) => {
  const [chatData, setChatData] = useState<any>('')
  useEffect(() => {
    if (!chatData) {
      getChatData(memberId, homeQualStaffId, true)
        .then((response: any) => {
          setChatData(response.data)
        })
        .catch((err: any) => {
          console.error(err)
        })
    }
  }, [chatData])

  const { user } = useAuth()

  return (
    <>
      {chatData && user && (
        <div className={className}>
          <ChatContainer
            userIdentifier={chatData.identityId}
            token={chatData.accessToken}
            displayName={user?.userName}
            endpointUrl={chatData.endpointUrl}
            threadId={chatData.threadId}
            isView={chatData.isView}
          />
        </div>
      )}
    </>
  )
}
