import React, {useEffect} from 'react'
import {ChatContainerPopup} from './ChatContainerPopup'
import {getChatData, getChatVisitorData} from '../../api/queries'
import {useAuth} from '../../contexts/auth'
import {LoadIndicator} from 'devextreme-react/load-indicator'
import {toast} from 'react-toastify'

export interface IChatWrap {
  closeHandler: any
  setMessage: any
  chatData: any
  setChatData: any
  tabs: any
  setTabs: any
  memberId?: string | null
  visitorIdentityId?: string | null
  threadId?: string | null
}

export const ChatWrap = (props: IChatWrap) => {
  const {
    memberId,
    visitorIdentityId,
    threadId,
    closeHandler,
    setMessage,
    chatData,
    setChatData,
    tabs,
    setTabs
  } = props
  const { user } = useAuth()

  useEffect(() => {
    if (!chatData && user) {
      if (memberId) {
        getChatData(memberId, user.userId)
          .then((response: any) => {
            const newTabs = tabs.map((tab: any) => {
              return tab?.memberId === memberId
                ? {
                    ...tab,
                    threadId: response.data.threadId,
                    visitorIdentityId: response.data.identityId,
                    advisorIdentityId: response.data.identityId,
                    advisorThreadId: response.data.threadId
                  }
                : tab
            })
            setTabs(newTabs)
            setChatData(response.data)
          })
          .catch((err: any) => {
            const newTabs = tabs.filter((tab: any) => {
              return tab?.memberId !== memberId
            })
            setTabs(newTabs)
            setChatData('')
            setMessage(false)
            console.error(err)
          })
      } else if (visitorIdentityId && threadId) {
        getChatVisitorData(visitorIdentityId, user.userId, threadId)
          .then((response: any) => {
            const newTabs = tabs.map((tab: any) => {
              return tab?.threadId === threadId
                ? {
                  ...tab,
                  advisorIdentityId: response.data.identityId,
                  advisorThreadId: response.data.threadId
                }
                : tab
            })
            setTabs(newTabs)
            setChatData(response.data)
          })
          .catch((err: any) => {
            const newTabs = tabs.filter((tab: any) => {
              return tab?.threadId !== threadId
            })
            setTabs(newTabs)
            setChatData('')
            setMessage(false)
            console.error(err)
            toast.error(
              'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
            )
          })
      }
    }
  }, [chatData, user])

  return (
    <>
      {chatData && user ? (
        <ChatContainerPopup
          tabs={tabs}
          userIdentifier={chatData.identityId}
          token={chatData.accessToken}
          endpointUrl={chatData.endpointUrl}
          threadId={chatData.threadId}
          displayName={user?.userName}
          closeHandler={closeHandler}
        />
      ) : (
        <div style={{ textAlign: 'center', paddingTop: '3px' }}>
          <LoadIndicator id="small-indicator" height={20} width={20} />
        </div>
      )}
    </>
  )
}
