import React, {FC} from 'react'
import {ChatWrap} from './ChatWrap'
import logoImg from '../../assets/images/hqlogolight.png'
import styled from '@emotion/styled'
import {useNavigate} from 'react-router-dom'

interface IChatWithTabs {
  tabs: any
  setTabs: any
  message: any
  setMessage: any
  chatVisible: boolean
  setChatVisible: any
  chatData: any
  setChatData: any
}

export const ChatWithTabs: FC<IChatWithTabs> = ({
  tabs,
  setTabs,
  message,
  setMessage,
  chatVisible,
  setChatVisible,
  chatData,
  setChatData
}) => {
  const ImageHolder = styled(({ ...props }) => <div {...props} />)`
    width: 100px;
    height: 35px;
    margin-bottom: 10px;
    background: url(${(props) => props.logo}) 100% 50% no-repeat;
    background-position-x: right;
    -webkit-background-size: contain;
    background-size: contain;
  `
  let visitorIndex = 0
  const navigate = useNavigate()

  return (
    <>
      {tabs.length > 0 && chatVisible && (
        <div
          style={{
            border: '1px solid #1ca8dd',
            paddingTop: '35px',
            width: '300px',
            position: 'fixed',
            top: '30%',
            right: '15px',
            backgroundColor: '#fff',
            zIndex: 5
          }}>
          <div
            style={{
              position: 'absolute',
              top: '0px',
              fontWeight: '700',
              fontSize: '15px',
              padding: '4px 10px',
              color: '#1ca8dd'
            }}>
            <ImageHolder logo={logoImg} />
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              borderBottom: '1px solid #1ca8dd'
            }}>
            {tabs.map((item: any, index: number) => {
              if (!item.memberName) {
                visitorIndex++
              }

              return (
                <span
                  style={{
                    width: '140px',
                    cursor: 'pointer',
                    display: 'inlineBlock',
                    padding: '10px',
                    margin: '3px',
                    borderRadius: '5px',
                    background: `${item.color || '#1ca8dd'}`,
                    color: '#fff',
                    boxShadow: '2px 1px #ccc'
                  }}
                  onClick={() => {
                    setMessage({
                      memberId: item.memberId,
                      visitorIdentityId: item.visitorIdentityId,
                      threadId: item.threadId
                    })
                    setChatData('')
                    if (item.memberId) {
                      navigate(`/user-portal/1/${item.memberId}`)
                    }
                  }}
                  key={index}>
                  {item.memberId && item.memberName
                    ? `${item.memberName}`
                    : `Visitor chat #${visitorIndex}`}
                </span>
              )
            })}
          </div>
          {message && (
            <ChatWrap
              tabs={tabs}
              setTabs={setTabs}
              chatData={chatData}
              setChatData={setChatData}
              memberId={message.memberId}
              visitorIdentityId={message.visitorIdentityId}
              threadId={message.threadId}
              setMessage={setMessage}
              closeHandler={() => {
                setChatVisible(false)
                setTabs([])
              }}
            />
          )}
        </div>
      )}
    </>
  )
}
