import React, {useCallback, useState} from 'react'

import PieChart, {Connector, Format, Label, Legend, Series, Tooltip} from 'devextreme-react/pie-chart'

import Funnel, {
  Border as BorderFunnel,
  Item as ItemFunnel,
  Margin as MarginFunnel,
  Title as TitleFunnel
} from 'devextreme-react/funnel'

import RangeSelector, {Behavior, Margin, Marker, MinorTick, Scale, SliderMarker} from 'devextreme-react/range-selector'

import {useAuth} from '../../contexts/auth'
import {apiDomain} from '../../api/queries'
import {useTransport, useTransportSWR} from '../../shared/hooks/use-transport'
import {LoadPanel} from 'devextreme-react/load-panel'
import moment from 'moment'

export const AdminDashboard = () => {
  const customizeTooltip = (arg: any) => {
    return {
      text: `${(arg.percent * 100).toFixed(2)}%`
    }
  }

  const customizeText = (arg: any) => {
    return arg.percentText
  }

  const { user } = useAuth()

  const transport = useTransport(apiDomain)

  const endValue = new Date()
  const startValueVal = new Date()
  const startValue = startValueVal.setFullYear(startValueVal.getFullYear() - 1)

  const [dateRangeFilter, setDateRangeFilter] = useState('')
  const [dateAr, setDateAr] = useState([startValue, endValue])

  const { data: membersGroup } = useTransportSWR<any>(
    transport,
    `/Report/Transactions/DataSource?CallerId=${user?.userId}&CallerTypeId=${user?.userTypeId}${dateRangeFilter}&group=[{"selector":"serviceTypeDescription","desc":false,"isExpanded":false}]`
  )

  const { data: members } = useTransportSWR<any>(
    transport,
    `/Report/Transactions/DataSource?CallerId=${user?.userId}&CallerTypeId=${user?.userTypeId}${dateRangeFilter}`
  )

  let data: any = []
  let dataSource: any = []
  let completedQuestions = 0
  let gotLender = 0
  let gotAgent = 0
  let closed = 0
  if (membersGroup) {
    data = membersGroup.data.map((member: any) => {
      return {
        type: member.key,
        val: member.count
      }
    })
  }
  if (members) {
    members.data.map((member: any) => {
      if (member.allQuestionsCompletedFlag) {
        completedQuestions += 1
      }
      if (member.lenderId) {
        gotLender += 1
      }
      if (member.agentId) {
        gotAgent += 1
      }
      if (member.closeDate) {
        closed += 1
      }
    })
    dataSource = [
      { argument: 'New Members', value: members.data.length },
      { argument: 'Completed Questions', value: completedQuestions },
      { argument: 'Got a Lender', value: gotLender },
      { argument: 'Got an Agent', value: gotAgent },
      { argument: 'Closed', value: closed }
    ]
  }

  const formatLabel = (arg: any) => {
    return `<span class="label">${arg.percentText}</span><br/>${arg.item.argument}`
  }

  const processRange = useCallback((e: any) => {
    const start = moment(e.value[0]).format('YYYY-MM-DD')
    const end = moment(e.value[1]).format('YYYY-MM-DD')
    if (dateRangeFilter === `&filter=[["memberCreatedDate",">=","${start}"],"and",["memberCreatedDate","<","${end}"]]`) {
      return
    }
  
    setDateAr(e.value)
    setDateRangeFilter(
      `&filter=[["memberCreatedDate",">=","${start}"],"and",["memberCreatedDate","<","${end}"]]`
    )
  }, [dateRangeFilter])

  return (
    <>
      {members && membersGroup ? (
        <>
          <div className="pies-container">
            <PieChart
              id="pie"
              className="pie"
              type="doughnut"
              title="Visitors by Service Type"
              palette="Soft Pastel"
              dataSource={data}>
              <Series argumentField="type">
                <Label customizeText={customizeText} visible={true} format="percent">
                  <Connector visible={true} />
                </Label>
              </Series>
              <Legend
                margin={0}
                horizontalAlignment="right"
                verticalAlignment="top"
              />
              <Tooltip enabled={true} customizeTooltip={customizeTooltip}>
                <Format type="percent" />
              </Tooltip>
            </PieChart>
            <Funnel
              id="funnel"
              dataSource={dataSource}
              palette="Soft Pastel"
              argumentField="argument"
              valueField="value">
              <TitleFunnel text="Homebuyer Funnel Chart">
                <MarginFunnel bottom={30} />
              </TitleFunnel>
              <Tooltip enabled={true} format="fixedPoint" />
              <ItemFunnel>
                <BorderFunnel visible={true} />
              </ItemFunnel>
              <Label
                font={{ color: '#333' }}
                visible={true}
                position="outside"
                backgroundColor="none"
                customizeText={formatLabel}
              />
            </Funnel>
          </div>
          <div id="range-selector-demo">
            <RangeSelector
              id="range-selector"
              value={{
                startValue: dateAr[0],
                endValue: dateAr[1]
              }}
              title="Date Range Selector"
              onValueChanged={processRange}>
              <Margin top={50} />
              <Scale
                startValue={startValue}
                endValue={endValue}
                minorTickInterval="day"
                tickInterval="month">
                <MinorTick visible={false} />
                <Marker visible={false} />
                <Label format="MMM" />
              </Scale>
              <Behavior callValueChanged="onMovingComplete" />
              <SliderMarker format="dd EEEE" />
            </RangeSelector>
          </div>
        </>
      ) : (
        <LoadPanel
          shadingColor="rgba(255,255,255,0.4)"
          visible={true}
          showIndicator={true}
          shading={true}
          showPane={true}
        />
      )}
    </>
  )
}
