import React, {FC, useState} from 'react'
import Button from 'devextreme-react/button'
import {toast} from 'react-toastify'
import {Popup} from 'devextreme-react/popup'
import TextBox from 'devextreme-react/text-box'
import {isAdministratorPermission} from '../../../shared/functions'

const RenderContent = (props: any) => {
  const { togglePopup, params, resetPasswordHandler } = props
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState(params.password)
  const updateParams = { ...params, password: password }
  const onValueChanged = (data: any) => {
    setPassword(data.value)
  }
  return (
    <>
      <div className="popup-reset-password-description">
        Please enter a new password
      </div>
      <div
        style={{ marginTop: '30px' }}
        className="popup-reset-password-description">
        <TextBox
          style={{ margin: '0 auto' }}
          width="300px"
          value={password}
          defaultValue={password}
          onValueChanged={onValueChanged}
        />
      </div>
      <div style={{ marginTop: '20px' }} className="popup-reset-password-wrap">
        <Button
          className={'btn-hq-color user-image-button popup-reset-password'}
          stylingMode={'text'}
          onClick={() => {
            if (loading) {
              return
            }
            setLoading(true)
            resetPasswordHandler(updateParams).then((response: any) => {
              toast.success('Password has been changed')
              setTimeout(() => {
                setLoading(false)
                togglePopup()
              }, 1000)
            })
          }}>
          <span className="dx-button-text">{loading ? 'Done' : 'Yes'}</span>
        </Button>
        <Button
          className={'btn-hq-color user-image-button popup-reset-password'}
          stylingMode={'text'}
          onClick={() => {
            togglePopup()
          }}>
          <span className="dx-button-text">No</span>
        </Button>
      </div>
    </>
  )
}

export const ResetPassword: FC<{
  params?: any
  resetPasswordHandler?: any
}> = ({ params, resetPasswordHandler }) => {
  const [isPopupVisible, setPopupVisibility] = useState(false)
  const togglePopup = () => {
    setPopupVisibility(!isPopupVisible)
  }

  function renderPopup(props: any) {
    return (
      <RenderContent
        params={params}
        resetPasswordHandler={resetPasswordHandler}
        togglePopup={togglePopup}
      />
    )
  }

  return (
    <>
      <Popup
        title="Reset password"
        contentRender={renderPopup}
        visible={isPopupVisible}
        hideOnOutsideClick={true}
        onHiding={togglePopup}
        width={400}
        height={280}
      />
      <Button
        className={'btn-hq-color user-image-button reset-password'}
        stylingMode={'text'}
        onClick={() => {
          if (
            +params.id !== params.userId &&
            !isAdministratorPermission(params.permissionLevel)
          ) {
            return
          }
          togglePopup()
        }}>
        <span className="dx-button-text">Reset Password</span>
      </Button>
    </>
  )
}
