import {FC} from 'react'
import {Button} from 'devextreme-react'
import {apiConsumerUrl} from 'api/queries'

const CONSUMER_QA_URI = '/journey'
const CONSUMER_SUCCESS_URI = '/success'

const openConsumerJourney = (consumerUri: string, memberId: string) => {
  const authToken = sessionStorage.getItem('authToken')

  window.open(
    apiConsumerUrl +
      `?consumerUri=${consumerUri}` +
      `&authToken=${authToken}` +
      `&memberId=${memberId}`,
    '_blank'
  )
}

export const SuccessPlanButton: FC<{ memberId?: string }> = ({ memberId }) => {
  if (!memberId) {
    return null
  }

  return (
    <Button
      className={'btn-hq-color user-image-button detail-button'}
      stylingMode={'text'}
      onClick={() => openConsumerJourney(CONSUMER_SUCCESS_URI, memberId)}>
      <span className="dx-button-text">Success Plan</span>
    </Button>
  )
}

export const QuestionsAnswersButton: FC<{
  memberId?: string
  lastQuestionCompleted: number
}> = ({ memberId, lastQuestionCompleted }) => {
  if (!memberId) {
    return null
  }

  return (
    <Button
      className={'btn-hq-color user-image-button detail-button'}
      stylingMode={'text'}
      onClick={() => {
        const questionNum =
          lastQuestionCompleted === 1 ? 2 : lastQuestionCompleted
        openConsumerJourney(CONSUMER_QA_URI + '/Q' + questionNum, memberId)
      }}>
      <span className="dx-button-text">Q&A</span>
    </Button>
  )
}
