import {useEffect, useRef} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import styled from '@emotion/styled'
import Form, {EmailRule, GroupItem, RequiredRule, SimpleItem} from 'devextreme-react/form'
import 'devextreme-react/text-area'
import {useTransport, useTransportSWR} from '../../../shared/hooks/use-transport'
import useFormDataHandlerContext from '../../../shared/hooks/use-form-data-handler-context'
import {
  lenderInsertQuery,
  lenderPatchTerritoryQuery,
  lenderResetPasswordQuery,
  lenderUpdateQuery
} from '../../../api/queries'
import {useAuth} from '../../../contexts/auth'
import {Territories} from './territories'
import {ImageUploader} from './image-uploader'
import {useSWRConfig} from 'swr'
import {LoadPanel} from 'devextreme-react/load-panel'
import {ResetPassword} from './reset-password'
import {AccountRouteParam} from 'shared/enums'
import {apiDomain} from 'api/queries'
import {UserTypeId} from '../../../shared/enums/index'
import {isHqStaffPermissionLessThan70} from '../../../shared/functions'
import {toast} from 'react-toastify'

const ListContainer = styled.div`
  margin: 40px 40px 60px;
`

export const AccountLendersDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const transport = useTransport(apiDomain)

  const { data: lender } = useTransportSWR<any>(transport, `/Lender/4/${id}`)

  const { data: territory } = useTransportSWR<any>(
    transport,
    `/Lender/Territory/4/${id}`
  )

  const { currentFormData, setCurrentFormData } = useFormDataHandlerContext()
  const lenderInitial = Object.assign({}, lender)

  const formSaveHandler = (data: any) => {
    if (!data) {
      return
    }
    lenderUpdateQuery(data)
      .then((response: any) => {
        console.log(response)
      })
      .catch((err: any) => {
        console.error(err)
        toast.error(
          'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
        )
      })
  }
  const formSaveNewHandler = (data: any) => {
    if (!data) {
      return
    }
    lenderInsertQuery(data)
      .then((response: any) => {
        if (response.data.entity) {
          navigate(
            `/accounts/${AccountRouteParam.Lenders}/${response.data.entity.lenderId}`
          )
        } else if (response.data.error) {
          toast.error(
            response.data.error?.uiDisplayMessage ||
              'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
          )
        }
        console.log(response)
      })
      .catch((err: any) => {
        console.error(err)
        toast.error(
          'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
        )
      })
  }

  const { user } = useAuth()
  const lenderInitialData = {
    bilingualFlag: false,
    isActiveFlag: false,
    firstName: null,
    lastName: null,
    address: null,
    city: null,
    stateId: null,
    zipCode: null,
    emailAddress: null,
    phone: null,
    phoneExtension: null,
    phoneForSms: null,
    agreementSignedDate: null,
    imageFile: null,
    mlsNumber: null,
    mlsExpiryDate: null,
    businessName: null,
    businessPhone: null,
    websiteAddress: null,
    comments: null,
    apiString: null,
    callerId: user?.userId,
    callerTypeId: user?.userTypeId
  }
  const { mutate } = useSWRConfig()

  const territoryPatch = (territories: any) => {
    if (!id) {
      return
    }

    territories.map((territory: any, index: number) => {
      lenderPatchTerritoryQuery(id, territory).then(() => {
        if (index === territories.length - 1) {
          mutate(`/Lender/Territory/4/${id}`)
        }
      })
    })
  }

  useEffect(() => {
    const currentFormDataNew = Object.assign({}, currentFormData)
    currentFormDataNew.isListPage = false
    currentFormDataNew.territories = []
    currentFormData.imageFile = false
    currentFormDataNew.territorySave = territoryPatch
    currentFormDataNew.addUrl = false
    currentFormDataNew.isFormPage = true
    currentFormDataNew.formSaveHandler =
      lender && lender.entity ? formSaveHandler : formSaveNewHandler
    setCurrentFormData(currentFormDataNew)
  }, [lender])

  const formRef = useRef<any>()

  const currentFormDataNew = Object.assign({}, currentFormData)

  const formDataNew =
    lender && lender.entity
      ? lender.entity
      : currentFormDataNew.formDataNew
      ? currentFormDataNew.formDataNew
      : lenderInitialData

  const { data: states } = useTransportSWR<any>(transport, `/ReferenceState`)
  let statesDescription = []
  let currentState = []
  if (states) {
    statesDescription = states.states.map((state: any) => {
      return state.description
    })
    if (formDataNew.stateId) {
      currentState = states.states.filter((state: any) => {
        return state.stateId.toLowerCase() === formDataNew.stateId.toLowerCase()
      })
    }
  }

  const onFieldDataChangedHandler = (data: any) => {
    if (!formDataNew || !data.value === null || data.value === undefined) {
      return
    }
    const currentFormDataNew = Object.assign({}, currentFormData)
    currentFormDataNew.isFormDataChanged = true
    currentFormDataNew.formRef = formRef
    currentFormDataNew.formData = lenderInitial
    formDataNew[data.dataField] = data.value

    if (data.dataField === 'Phone') {
      formDataNew.phone = data.value || null
      formDataNew.Phone = data.value || null
    } else if (data.dataField === 'BusinessPhone') {
      formDataNew.businessPhone = data.value || null
      formDataNew.BusinessPhone = data.value || null
    }

    if (data.dataField === 'MLSNumber') {
      formDataNew.mlsNumber = data.value || null
      formDataNew.MLSNumber = data.value || null
    }

    if (data.dataField === 'state') {
      const currentStateId = states.states.filter((state: any) => {
        return (
          state.description.toLowerCase() ===
          (data.value ? data.value.toLowerCase() : null)
        )
      })
      formDataNew.stateId = currentStateId[0] ? currentStateId[0].stateId : null
    }

    if (data.dataField === 'signedDate') {
      formDataNew.agreementSignedDate = +data.value !== 0 ? data.value : null
    }

    if (data.dataField === 'extension') {
      formDataNew.phoneExtension = +data.value !== 0 ? data.value : null
    }

    if (data.dataField === 'active') {
      formDataNew.isActiveFlag = data.value || false
    }

    if (data.dataField === 'expires') {
      formDataNew.mlsExpiryDate = +data.value !== 0 ? data.value : null
    }

    formDataNew.BusinessPhone = formDataNew.BusinessPhone || null
    currentFormDataNew.formDataNew = formDataNew
    currentFormDataNew.validate = data.component
    setCurrentFormData(currentFormDataNew)
  }

  const onValueChange = (
    value: boolean,
    stateId: string,
    allRecordsFlag: boolean = false
  ) => {
    if (!user) {
      return
    }

    const params = {
      stateId: stateId,
      isActiveFlag: value,
      allRecordsFlag: allRecordsFlag,
      callerId: user?.userId,
      callerTypeId: user?.userTypeId
    }
    const currentFormDataNew = Object.assign({}, currentFormData)
    currentFormDataNew.isFormDataChanged = true
    if (allRecordsFlag) {
      currentFormDataNew.territories = [params]
      setCurrentFormData(currentFormDataNew)
      return
    }
    currentFormDataNew.territories.push(params)
    setCurrentFormData(currentFormDataNew)
  }

  const { data: imageFile } = useTransportSWR<any>(
    transport,
    `/File?fileName=${currentFormData.imageFile || formDataNew.imageFile}`
  )

  const resetPasswordHandler = (params: any) => {
    return lenderResetPasswordQuery(params)
  }

  const resetPasswordParams = lender &&
    lender.entity && {
      lenderId: lender?.entity.lenderId,
      userTypeId: lender?.entity.userTypeId,
      emailAddress: lender?.entity.emailAddress,
      password: lender?.entity.password,
      userId: user?.userId,
      permissionLevel: user?.permissionLevel,
      id: id
    }

  return (
    <>
      <ListContainer>
        {!id || lender ? (
          <>
            <Form
              ref={formRef}
              labelLocation="left"
              formData={formDataNew}
              onFieldDataChanged={onFieldDataChangedHandler}
              showColonAfterLabel={false}
              showRequiredMark={false}>
              <GroupItem colCount={2}>
                <GroupItem caption="Lender">
                  <SimpleItem dataField="businessName">
                    <RequiredRule message="Business Name is required" />
                  </SimpleItem>
                  <SimpleItem dataField="address" />
                  <SimpleItem dataField="city" />
                  <SimpleItem
                    editorType="dxSelectBox"
                    editorOptions={{
                      value: currentState[0] && currentState[0].description,
                      items: statesDescription
                    }}
                    dataField="state"
                  />
                  <SimpleItem dataField="zipCode" />
                  <SimpleItem
                    dataField="BusinessPhone"
                    editorOptions={{
                      value:
                        formDataNew && formDataNew.businessPhone !== null
                          ? formDataNew.businessPhone.toString()
                          : '',
                      mask: '(X00) 000-0000',
                      maskRules: { X: /[1-9]/ }
                    }}
                  />
                  <SimpleItem dataField="websiteAddress" />
                  {lender && lender.entity && (
                    <GroupItem>
                      <ResetPassword
                        params={resetPasswordParams}
                        resetPasswordHandler={resetPasswordHandler}
                      />
                    </GroupItem>
                  )}
                </GroupItem>
                <GroupItem caption="Status">
                  <GroupItem>
                    <SimpleItem
                      dataField="active"
                      editorOptions={{
                        value: formDataNew.isActiveFlag,
                        height: '37px'
                      }}
                    />
                    <SimpleItem
                      editorOptions={{
                        value:
                          formDataNew && formDataNew.agreementSignedDate !== 0
                            ? formDataNew.agreementSignedDate
                            : null,
                        width: '80%'
                      }}
                      editorType="dxDateBox"
                      dataField="signedDate"
                    />
                    <SimpleItem
                      label={{text: 'MLS Number'}}
                      editorOptions={{
                        value:
                          formDataNew && formDataNew.mlsNumber
                            ? formDataNew.mlsNumber
                            : null
                      }}
                      dataField="MLSNumber"
                    />
                    <SimpleItem
                      editorType="dxDateBox"
                      dataField="expires"
                      editorOptions={{
                        value:
                          formDataNew && formDataNew.mlsExpiryDate !== 0
                            ? formDataNew.mlsExpiryDate
                            : null
                      }}
                    />
                    <GroupItem cssClass="user-image-container">
                      {id && +id > 0 && (
                        <ImageUploader
                          userTypeId={UserTypeId.Lender}
                          entityId={id}
                          currentImg={
                            formDataNew.imageFile || currentFormData.imageFile
                              ? imageFile
                              : false
                          }
                        />
                      )}
                    </GroupItem>
                  </GroupItem>
                </GroupItem>
              </GroupItem>
              <GroupItem colCount={2}>
                <GroupItem>
                  <GroupItem caption="Primary Contact" />
                  <SimpleItem dataField="firstName">
                    <RequiredRule message="First Name is required" />
                  </SimpleItem>
                  <SimpleItem dataField="lastName">
                    <RequiredRule message="Last Name is required" />
                  </SimpleItem>
                  <SimpleItem
                    dataField="Phone"
                    editorOptions={{
                      value:
                        formDataNew && formDataNew.phone !== null
                          ? formDataNew.phone.toString()
                          : '',
                      mask: '(X00) 000-0000',
                      maskRules: { X: /[1-9]/ }
                    }}
                  />
                  <SimpleItem
                    editorOptions={{
                      value:
                        formDataNew && formDataNew.phoneExtension !== 0
                          ? formDataNew.phoneExtension
                          : null
                    }}
                    dataField="extension"
                  />
                  <SimpleItem dataField="emailAddress">
                    <RequiredRule message="Email is required" />
                    <EmailRule message="Email is invalid" />
                  </SimpleItem>
                </GroupItem>
                <GroupItem>
                  <GroupItem>
                    <GroupItem caption="Comments" />
                    <SimpleItem
                      label={{ text: ' ' }}
                      editorType="dxTextArea"
                      dataField="comments"
                    />
                  </GroupItem>
                </GroupItem>
              </GroupItem>
            </Form>
            {territory &&
              territory.entity.length > 0 &&
              !isHqStaffPermissionLessThan70(user?.permissionLevel) && (
                <div className="marginTop">
                  <Territories data={territory} onValueChange={onValueChange} />
                </div>
              )}
          </>
        ) : (
          <LoadPanel
            shadingColor="rgba(255,255,255,0.4)"
            visible={true}
            showIndicator={true}
            shading={true}
            showPane={true}
          />
        )}
      </ListContainer>
    </>
  )
}
