import 'devextreme/dist/css/dx.common.css'
import './themes/generated/theme.base.css'
import './themes/generated/theme.additional.css'
import {useState} from 'react'
import {HashRouter as Router} from 'react-router-dom'
import './dx-styles.scss'
import LoadPanel from 'devextreme-react/load-panel'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {getAppVersion} from 'utils/version'
import {NavigationProvider} from './contexts/navigation'
import {AuthProvider, useAuth} from './contexts/auth'
import {useScreenSizeClass} from './utils/media-query'
import Content from './Content'
import UnauthenticatedContent from './UnauthenticatedContent'
import {FormDataHandlerContext} from './shared/context/form-data-handler-context'
import axios from 'axios'

window.addEventListener('unload', () => {
  const apiDomain =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_SERVER_API_URL
      : ''
  const apiChatUrl = `${apiDomain}/Api/Acs/Chat`
  const identityId = sessionStorage.getItem('identityId')
  const threadId = sessionStorage.getItem('threadId')
  if (identityId && threadId) {
    navigator.sendBeacon(`${apiChatUrl}/Closing/Advisor/${threadId}/${identityId}`)
  }
})

function App() {
  const { user, loading } = useAuth()
  if (loading) {
    return <LoadPanel visible={true} />
  }

  if (user) {
    return <Content />
  }

  return <UnauthenticatedContent />
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass()
  const [currentFormData, setCurrentFormData] = useState({})
  if (sessionStorage.authToken) {
    axios.defaults.headers.common.authorization = `Bearer ${sessionStorage.authToken}`
  }

  return (
    <Router>
      <FormDataHandlerContext.Provider
        value={{ currentFormData: currentFormData, setCurrentFormData }}>
        <AuthProvider>
          <NavigationProvider>
            <HelmetProvider>
              <div className={`app ${screenSizeClass}`}>
                <Helmet>
                  <meta name="version" content={getAppVersion()} />
                </Helmet>
                <App />
              </div>
            </HelmetProvider>
          </NavigationProvider>
        </AuthProvider>
      </FormDataHandlerContext.Provider>
    </Router>
  )
}
