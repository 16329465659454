import {useEffect, useRef} from 'react'
import useFormDataHandlerContext from '../../../shared/hooks/use-form-data-handler-context'
import DataGrid, {Column, Export, FilterRow, Item, Pager, Selection, Toolbar} from 'devextreme-react/data-grid'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../contexts/auth'
import {createStore} from 'devextreme-aspnet-data-nojquery'
import {phoneFormat} from '../../../shared/functions'
import {apiDomain} from 'api/queries'
import {DataGridTitle, PageContainer} from 'shared/components/layout'

export const AccountsListAgent = () => {
  const { setCurrentFormData } = useFormDataHandlerContext()

  useEffect(() => {
    setCurrentFormData({ isListPage: true, addUrl: '/accounts/agents/0' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const authToken = sessionStorage.getItem('authToken')

  const { user } = useAuth()
  const { current: dataSourceRef } = useRef(
    createStore({
      key: 'agentId',
      loadUrl:
        apiDomain +
        '/Api/Admin/Agent/DataSource' +
        `?CallerId=${user?.userId}&CallerTypeId=${user?.userTypeId}`,
      onBeforeSend: (_, ajaxSettings) => {
        ajaxSettings.headers = {
          Authorization: `Bearer ${authToken}`
        }
      }
    })
  )
  const navigate = useNavigate()
  const activeRender = (cellData: any) => {
    if (!cellData.value) {
      return
    }
    return <div className="dx-icon-check"></div>
  }
  const phoneRender = (cellData: any) => {
    if (!cellData.value) {
      return
    }
    return phoneFormat(cellData.value)
  }

  return (
    <PageContainer>
      <DataGrid
        dataSource={dataSourceRef}
        remoteOperations={{ paging: true, sorting: true, filtering: true }}
        hoverStateEnabled={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        allowColumnReordering={true}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        onSelectionChanged={(value) => {
          const memberId = value.selectedRowsData[0]?.agentId
          if (!memberId) {
            return
          }
          navigate(`${memberId}`)
        }}>
        {user && user.permissionLevel >= 90 && <Export enabled={true} />}
        <FilterRow showOperationChooser={false} visible={true}></FilterRow>
        <Selection mode="single" />
        <Column dataField="firstName" caption="First Name" />
        <Column dataField="lastName" caption="Last Name" />
        <Column dataField="emailAddress" caption="Email" />
        <Column
          dataType="string"
          dataField="phone"
          alignment="left"
          caption="Phone"
          cellRender={phoneRender}
        />
        <Column dataField="brokerName" caption="Broker" />
        <Column dataField="city" caption="City" />
        <Column dataField="stateDescription" caption="State" />
        <Column
          dataField="isActiveFlag"
          caption="Active"
          cellRender={activeRender}
          trueText="Active"
          falseText="Inactive"
        />

        <Toolbar>
          <Item location="before">
            <DataGridTitle>Agents</DataGridTitle>
          </Item>
          <Item name="exportButton" />
        </Toolbar>

        <Pager
          visible={true}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
      </DataGrid>
    </PageContainer>
  )
}
