import {Navigate, Route, Routes} from 'react-router-dom'
import {SingleCard} from './layouts'
import {ChangePasswordForm, CreateAccountForm, LoginForm, ResetPasswordForm} from './components'
import React from 'react'
import {ToastContainer} from 'react-toastify'

export default function UnauthenticatedContent() {
  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <>
              <SingleCard title="Sign In" logo={true}>
                <LoginForm />
              </SingleCard>
            </>
          }
        />
        <Route
          path="/create-account"
          element={
            <SingleCard title="Sign Up">
              <CreateAccountForm />
            </SingleCard>
          }
        />
        <Route
          path="/reset-password"
          element={
            <SingleCard
              title="Get Password"
              description="Please enter your email address and we will send you a new password via email.">
              <ResetPasswordForm />
            </SingleCard>
          }
        />
        <Route
          path="/change-password/:recoveryCode"
          element={
            <SingleCard title="Change Password">
              <ChangePasswordForm />
            </SingleCard>
          }
        />
        <Route path="*" element={<Navigate to={'/login'} />}></Route>
      </Routes>
      <ToastContainer />
    </>
  )
}
