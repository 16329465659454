import React, {FC, useState} from 'react'
import {NotesOrTasksGrid} from './notes-or-tasks-grid'
import moment from 'moment'
import {Popup} from 'devextreme-react/popup'
import Form, {GroupItem, SimpleItem} from 'devextreme-react/form'
import Button from 'devextreme-react/button'
import {useTransport, useTransportSWR} from '../../../../shared/hooks/use-transport'
import {apiDomain, memberNoteInsertQuery} from '../../../../api/queries'
import {useAuth} from '../../../../contexts/auth'
import {useSWRConfig} from 'swr'
import {toast} from 'react-toastify'

export const Notes: FC<{
  memberId: string
}> = ({ memberId }) => {
  const transport = useTransport(apiDomain)
  const { user } = useAuth()
  const dateRender = (cellData: any) => {
    return moment(cellData.value).format('MM/DD/YYYY')
  }
  const textRender = (cellData: any) => {
    return (
      <div className="textRender">
        <div className="textRenderFrom">{`By ${cellData.data.createdByFirstName} ${cellData.data.createdByLastName}`}</div>
        <div className="textRenderText">{cellData.value}</div>
      </div>
    )
  }
  const { data: dataSource } = useTransportSWR<any>(
    transport,
    `/MemberNote/${memberId}?CallerId=${user?.userId}&CallerTypeId=${user?.userTypeId}`
  )
  const { mutate } = useSWRConfig()
  const colums = [
    {
      name: 'Date',
      field: 'createdDate',
      render: dateRender,
      width: '100px',
      alignment: 'center'
    },
    {
      name: 'Note',
      field: 'note',
      render: textRender,
      alignment: 'left'
    }
  ]

  const [isPopupVisible, setPopupVisibility] = useState(false)
  const togglePopup = () => {
    setPopupVisibility(!isPopupVisible)
  }

  const RenderContent = (props: any) => {
    const { togglePopup, formData } = props
    const [note, setNote] = useState(formData.note)
    const saveHandler = () => {
      if (!user) {
        return
      }
      const params = {
        callerId: user.userId,
        callerTypeId: user.userTypeId,
        memberId: memberId,
        globalDisplayFlag: true,
        isActiveFlag: true,
        note: note
      }
      memberNoteInsertQuery(params)
        .then((response: any) => {
          mutate(
            `/MemberNote/${memberId}?CallerId=${user?.userId}&CallerTypeId=${user?.userTypeId}`
          )
          console.log(response)
        })
        .catch((err: any) => {
          console.error(err)
          toast.error(
            'Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.'
          )
        })
    }
    const onFieldDataChangedHandler = (data: any) => {
      setNote(data.value)
    }

    return (
      <>
        <Form
          labelLocation="left"
          formData={formData}
          onFieldDataChanged={onFieldDataChangedHandler}
          showColonAfterLabel={false}
          showRequiredMark={false}>
          <GroupItem>
            <SimpleItem
              label={{ text: ' ' }}
              editorType="dxTextArea"
              dataField="note"
              editorOptions={{ height: '180px', disabled: formData.exist }}
            />
          </GroupItem>
        </Form>
        <div className="popup-reset-password-wrap">
          {!formData.exist && (
            <Button
              className={'btn-hq-color user-image-button popup-reset-password'}
              stylingMode={'text'}
              onClick={() => {
                saveHandler()
                togglePopup()
              }}>
              <span className="dx-button-text">Save</span>
            </Button>
          )}

          <Button
            className={'btn-hq-color user-image-button popup-reset-password'}
            stylingMode={'text'}
            onClick={() => {
              togglePopup()
            }}>
            <span className="dx-button-text">
              {formData.exist ? 'Close' : 'Cancel'}
            </span>
          </Button>
        </div>
      </>
    )
  }

  const [formData, setFormData] = useState({ note: '' })

  const renderPopup = (props: any) => {
    return <RenderContent formData={formData} togglePopup={togglePopup} />
  }

  return (
    <>
      {dataSource && dataSource.entity && (
        <>
          <Popup
            title="NOTE"
            contentRender={renderPopup}
            visible={isPopupVisible}
            hideOnOutsideClick={true}
            onHiding={togglePopup}
            width={400}
            height={350}
          />
          <NotesOrTasksGrid
            dataSource={dataSource.entity}
            colums={colums}
            setFormData={setFormData}
            togglePopup={togglePopup}
            addButtonShow={true}
          />
        </>
      )}
    </>
  )
}
