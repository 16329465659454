import {useEffect, useState} from 'react'
import FileUploader from 'devextreme-react/file-uploader'
import ProgressBar from 'devextreme-react/progress-bar'
import styled from '@emotion/styled'
import {getFile} from '../../../api/queries'
import useFormDataHandlerContext from '../../../shared/hooks/use-form-data-handler-context'
import {apiDomain} from 'api/queries'
import {UserTypeId} from '../../../shared/enums/index'

interface IImageUploader {
  userTypeId?: number
  entityId?: string
  currentImg?: string
  mutate?: any
}

export const ImageUploader = (props: IImageUploader) => {
  const { userTypeId, entityId, currentImg } = props
  const [imageSource, setImageSource] = useState<any>(currentImg)
  const [isDropZoneActive, setIsDropZoneActive] = useState(false)
  const [textVisible, setTextVisible] = useState(true)
  const [progressVisible, setProgressVisible] = useState(false)
  const [progressValue, setProgressValue] = useState(0)
  const onDropZoneEnter = (e: any) => {
    if (e.dropZoneElement.id === 'dropzone-external') {
      setIsDropZoneActive(true)
    }
  }

  useEffect(() => {
    setImageSource(currentImg)
  }, [currentImg])

  const onDropZoneLeave = (e: any) => {
    if (e.dropZoneElement.id === 'dropzone-external') {
      setIsDropZoneActive(false)
    }
  }
  const { currentFormData, setCurrentFormData } = useFormDataHandlerContext()

  const onUploaded = (e: any) => {
    const { file } = e
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      const file = e.request.response
        .replace(/.*profiles\//, '')
        .replace(/\?.*/, '')
      getFile(file).then((response: any) => {
        setIsDropZoneActive(false)
        setImageSource(response.data)
        const currentFormDataNew = Object.assign({}, currentFormData)
        currentFormDataNew.isFormDataChanged = true
        currentFormDataNew.imageFile = file
        currentFormDataNew.formDataNew.imageFile = file
        setCurrentFormData(currentFormDataNew)
        setTextVisible(true)
        setProgressVisible(false)
        setProgressValue(0)
      })
    }
  }

  const onProgress = (e: any) => {
    setProgressValue((e.bytesLoaded / e.bytesTotal) * 100)
  }

  const onUploadStarted = () => {
    setImageSource('')
    setProgressVisible(true)
  }

  const ImageHolder = styled(({ ...props }) => <div {...props} />)`
    border-radius: ${(props) => (props.userTypeId === UserTypeId.Lender ? '' : '50%')};
    min-width: 100px;
    min-height: ${(props) => (props.userTypeId === UserTypeId.Lender ? '60px' : '100px')};
    max-width: 150px;
    max-height: 150px;
    width: ${(props) => (props.userTypeId === UserTypeId.Lender ? '13vw' : '10vw')};
    height: ${(props) => (props.userTypeId === UserTypeId.Lender ? 'auto' : '10vw')};
    background: url(${(props) => props.userimage}) 100% 100% no-repeat;
    background-size: contain;
  `

  return (
    <div className="widget-container flex-box">
      {(imageSource && (
        <ImageHolder
          userTypeId={userTypeId}
          id="dropzone-image"
          userimage={imageSource}
        />
      )) || <div className="no-photo">No Image on File</div>}
      <div
        id="dropzone-external"
        className={`flex-box ${
          isDropZoneActive
            ? 'dx-theme-accent-as-border-color dropzone-active'
            : 'dx-theme-border-color'
        }`}>
        {textVisible && (
          <div id="dropzone-text" className="flex-box">
            <span>Drag & Drop file</span>
            <span>or click.</span>
          </div>
        )}
        <ProgressBar
          id="upload-progress"
          min={0}
          max={100}
          width="30%"
          showStatus={false}
          visible={progressVisible}
          value={progressValue}></ProgressBar>
      </div>
      <FileUploader
        id="file-uploader"
        dialogTrigger="#dropzone-external"
        dropZone="#dropzone-external"
        multiple={false}
        allowedFileExtensions={['.jpg', '.jpeg', '.png']}
        uploadMode="instantly"
        uploadUrl={`${apiDomain}/Api/Admin/File/${userTypeId}/${entityId}`}
        visible={false}
        onDropZoneEnter={onDropZoneEnter}
        onDropZoneLeave={onDropZoneLeave}
        onUploaded={onUploaded}
        onProgress={onProgress}
        onUploadStarted={onUploadStarted}
        uploadHeaders={{
          authorization: `Bearer ${sessionStorage.authToken}`
        }}></FileUploader>
    </div>
  )
}
