import {
  Column,
  DataGrid,
  Export,
  FilterRow,
  Grouping,
  GroupPanel,
  Item,
  Selection,
  Toolbar
} from 'devextreme-react/data-grid'
import moment from 'moment'
import {useAuth} from '../../contexts/auth'
import {DataGridTitle, PageContainer} from '../../shared/components/layout'
import {useCallback, useEffect, useRef} from 'react'
import {createStore} from 'devextreme-aspnet-data-nojquery'
import {apiDomain} from '../../api/queries'
import {numberWithCommas, phoneFormat} from '../../shared/functions'
import useFormDataHandlerContext from '../../shared/hooks/use-form-data-handler-context'
import {Workbook} from 'exceljs'
import {exportDataGrid} from 'devextreme/excel_exporter'
import {saveAs} from 'file-saver-es'

export const TransactionsByService = () => {
  const { setCurrentFormData } = useFormDataHandlerContext()

  useEffect(() => {
    setCurrentFormData({})
  }, [])

  const dateRender = (cellData: any) => {
    if (!cellData.value) {
      return ''
    }
    return moment(cellData.value).format('MM/DD/YYYY')
  }

  const activeRender = (cellData: any) => {
    if (!cellData.value) {
      return
    }
    return <div className="dx-icon-check" />
  }

  const relationshipRender = (cellData: any) => {
    if (!cellData.data.coRelationshipId) {
      return ''
    }
    return cellData.value
  }

  const authToken = sessionStorage.getItem('authToken')
  const { user } = useAuth()
  const { current: dataSourceRef } = useRef(
    createStore({
      key: 'homeQualStaffId',
      loadUrl:
        apiDomain +
        `/Api/Admin/Report/Transactions/DataSource` +
        `?CallerId=${user?.userId}&CallerTypeId=${user?.userTypeId}`,
      onBeforeSend: (_, ajaxSettings) => {
        ajaxSettings.headers = {
          Authorization: `Bearer ${authToken}`
        }
      }
    })
  )

  const phoneRender = (cellData: any) => {
    if (!cellData.value) {
      return
    }
    return phoneFormat(cellData.value)
  }

  const serviceRender = (cellData: any) => {
    return `${cellData.value || ''}: ${
      cellData.data.collapsedItems
        ? cellData.data.collapsedItems.length
        : cellData.data.items.length
    }`
  }

  const formatWithComma = (cellData: any) => {
    return numberWithCommas(cellData.value)
  }

  const onExporting = useCallback((e: any) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Employees')
    exportDataGrid({
      component: e.component,
      worksheet: worksheet
    }).then(function () {
      workbook.csv.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Report.csv'
        )
      })
    })

    e.cancel = true
  }, [])

  return (
    <>
      <PageContainer>
        <DataGrid
          dataSource={dataSourceRef}
          onExporting={onExporting}
          remoteOperations={{
            paging: true,
            filtering: true,
            sorting: true
          }}
          hoverStateEnabled={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          allowColumnReordering={true}
          showBorders={true}
          showColumnLines={true}
          showRowLines={true}>
          <GroupPanel visible={true} />
          <Grouping autoExpandAll={false} />
          <FilterRow showOperationChooser={false} visible={true} />
          <Selection mode="single" />
          <Export enabled={true} formats={['csv']} />
          <Column
            dataField="serviceTypeDescription"
            caption="Service"
            groupCellRender={serviceRender}
            groupIndex={0}
          />
          <Column dataField="memberId" alignment="right" caption="Member ID" />
          <Column dataField="languageDescription" caption="Language" />
          <Column
            dataField="isActiveFlag"
            caption="Active"
            cellRender={activeRender}
            trueText="Active"
            falseText="Inactive"
          />
          <Column dataField="memberScore" caption="Score" />
          <Column dataField="sourceDescription" caption="Source" />
          <Column dataField="homeQualStaffName" caption="Advisor" />
          <Column dataField="lenderName" caption="Lender" />
          <Column dataField="agentName" caption="Agent" />
          <Column dataField="revenue" cellRender={formatWithComma} />
          <Column dataField="closeDate" cellRender={dateRender} />
          <Column
            dataField="rebateAmount"
            cellRender={formatWithComma}
            caption="Rebate"
          />
          <Column dataField="propertyValue" cellRender={formatWithComma} />
          <Column
            dataField="fundedDate"
            caption="Funded"
            cellRender={dateRender}
          />
          <Column
            dataField="verseActivatedFlag"
            caption="Verse Active"
            cellRender={activeRender}
            trueText="Active"
            falseText="Inactive"
          />
          <Column dataField="memberStatusDescription" caption="Status" />
          <Column dataField="firstName" />
          <Column dataField="lastName" />
          <Column dataField="address" />
          <Column dataField="city" />
          <Column dataField="stateDescription" caption="State" />
          <Column dataField="zipCode" caption="Zip" />
          <Column dataField="emailAddress" caption="Email" />
          <Column
            dataField="cellPhone"
            caption="Phone"
            cellRender={phoneRender}
          />
          <Column
            dataField="textOptInFlag"
            caption="Text OptIn"
            cellRender={activeRender}
          />
          <Column
            dataField="communicationPreferenceDescription"
            caption="Preference"
          />
          <Column
            dataField="communicationOptOutStatusDescription"
            caption="Opt Out"
          />
          <Column
            dataField="coRelationshipDescription"
            caption="Co-Borrower Relationship"
            cellRender={relationshipRender}
          />
          <Column dataField="coFirstName" caption="Co-Borrower First" />
          <Column dataField="coLastName" caption="Co-Borrower Last" />
          <Column dataField="coEmailAddress" caption="Co-Borrower Email" />
          <Column
            dataField="coCellPhone"
            caption="Co-Borrower Phone"
            cellRender={phoneRender}
          />
          <Column
            dataField="coCommunicationPreferenceDescription"
            caption="Co-Borrower Preference"
          />
          <Column
            dataField="memberLastUpdatedDate"
            caption="Last Activity"
            cellRender={dateRender}
          />
          <Column dataField="memberCreatedByUserName" caption="Created By" />
          <Column
            dataField="createdDate"
            caption="Start Date"
            cellRender={dateRender}
          />
          <Column
            dataField="qualificationScoreConventional"
            caption="Conv Score"
          />
          <Column dataField="qualificationScoreFha" caption="FHA Score" />
          <Column dataField="qualificationScoreVa" caption="VA Score" />
          <Column dataField="lastQuestionCompleted" caption="QA Completed" />
          <Column
            dataField="maritalStatusDescription"
            caption="Marital Status"
          />
          <Column
            dataField="refiPropertyValue"
            caption="Refi Property"
            cellRender={formatWithComma}
          />
          <Column
            dataField="refiPropertyOwe"
            caption="Owned"
            cellRender={formatWithComma}
          />
          <Column dataField="refiInterestRate" caption="Refi Interest" />
          <Column dataField="refiLoanTypeDescription" caption="Refi Loan" />
          <Column
            dataField="refiCreditScoreRange"
            caption="Refi Credit Score"
          />
          <Column dataField="refiAddress" caption="Refi Address" />
          <Column dataField="refiCity" caption="Refi City" />
          <Column dataField="refiStateDescription" caption="Refi State" />
          <Column
            dataField="refiZipCode"
            alignment="right"
            caption="Refi Zip"
          />
          <Column dataField="moveDate" cellRender={dateRender} />
          <Column dataField="moveRangeDescription" caption="Move Range" />
          <Column
            dataField="monthlyPaymentAmount"
            cellRender={formatWithComma}
            caption="Monthly Payment"
          />
          <Column dataField="monthlyIncome" cellRender={formatWithComma} />
          <Column dataField="homeSavings" cellRender={formatWithComma} />
          <Column
            dataField="homeSavingsCheckingFlag"
            caption="Checking"
            cellRender={activeRender}
          />
          <Column
            dataField="homeSavings401Flag"
            caption="401K"
            cellRender={activeRender}
          />
          <Column
            dataField="homeSavingsStocksFlag"
            caption="Stocks"
            cellRender={activeRender}
          />
          <Column
            dataField="homeSavingsGiftFlag"
            caption="Gift"
            cellRender={activeRender}
          />
          <Column
            dataField="homeSavingsOtherFlag"
            caption="Savings Other"
            cellRender={activeRender}
          />
          <Column
            dataField="autoExpense"
            cellRender={formatWithComma}
            caption="Auto Exp."
          />
          <Column
            dataField="creditCardExpense"
            cellRender={formatWithComma}
            caption="Credit Card Exp."
          />
          <Column
            dataField="studentLoans"
            cellRender={formatWithComma}
            caption="Student Loan Exp."
          />
          <Column
            dataField="otherExpenses"
            cellRender={formatWithComma}
            caption="Other Expenses"
          />
          <Column
            dataField="housingPaymentAmount"
            cellRender={formatWithComma}
            caption="Housing"
          />
          <Column
            dataField="veteranFlag"
            caption="Veteran"
            cellRender={activeRender}
          />
          <Column dataField="liveCity1" caption="Live City 1" />
          <Column dataField="liveState1" caption="Live State 1" />
          <Column dataField="liveCity2" caption="Live City 2" />
          <Column dataField="liveState2" caption="Live State 2" />
          <Column dataField="liveCity3" caption="Live City 3" />
          <Column dataField="liveState3" caption="Live State 3" />
          <Column
            dataField="coMonthlyIncome"
            cellRender={formatWithComma}
            caption="Co Income"
          />
          <Column
            dataField="coAutoExpense"
            cellRender={formatWithComma}
            caption="Co Auto"
          />
          <Column
            dataField="coCreditCardExpense"
            cellRender={formatWithComma}
            caption="Co Credit Card"
          />
          <Column
            dataField="coStudentLoans"
            cellRender={formatWithComma}
            caption="Co Student Loan"
          />
          <Column
            dataField="coOtherExpenses"
            cellRender={formatWithComma}
            caption="Co Other Exp."
          />
          <Column
            dataField="coHousingPaymentAmount"
            cellRender={formatWithComma}
            caption="Co Housing"
          />

          <Toolbar>
            <Item location="before">
              <DataGridTitle>Transactions</DataGridTitle>
            </Item>
            <Item name="exportButton" />
          </Toolbar>
        </DataGrid>
      </PageContainer>
    </>
  )
}
