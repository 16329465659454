import Toolbar, {Item} from 'devextreme-react/toolbar'
import Button from 'devextreme-react/button'
import UserPanel from '../user-panel/UserPanel'
import './Header.scss'
import {Template} from 'devextreme-react/core/template'
import useFormDataHandlerContext from '../../shared/hooks/use-form-data-handler-context'
import {useNavigate} from 'react-router-dom'
import type { HeaderProps } from '../../types'

export default function Header({
  menuToggleEnabled,
  title,
  toggleMenu
}: HeaderProps) {
  const navigate = useNavigate()
  const { currentFormData, setCurrentFormData } = useFormDataHandlerContext()
  return (
    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item
          visible={menuToggleEnabled}
          location={'before'}
          widget={'dxButton'}
          cssClass={'menu-button'}>
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item
          location={'before'}
          cssClass={'header-title'}
          text={title}
          visible={!!title}
        />
        <Item location={'before'}>
          {currentFormData.isFormPage && (
            <>
              <Button
                className={'btn-hq-color'}
                stylingMode={'text'}
                disabled={!currentFormData.isFormDataChanged}
                onClick={() => {
                  if (currentFormData.territories) {
                    currentFormData.territorySave(currentFormData.territories)
                    const currentFormDataNew = Object.assign(
                      {},
                      currentFormData
                    )
                    currentFormDataNew.isFormDataChanged = false
                    currentFormDataNew.territories = false
                    setCurrentFormData(currentFormDataNew)
                  }
                  if (currentFormData.formDataNew) {
                    if (currentFormData.validate._groupsColCount.length === 0) {
                      return
                    }
                    const validate = currentFormData.validate.validate()
                    if (!validate.isValid) {
                      return
                    }
                    currentFormData.formSaveHandler(currentFormData.formDataNew)
                  }
                }}>
                <span className="dx-button-text">Save</span>
              </Button>
            </>
          )}
        </Item>
        <Item location={'before'}>
          {currentFormData.isListPage && (
            <Button
              className={'btn-hq-color'}
              stylingMode={'text'}
              onClick={() => {
                if (currentFormData.navigationCb) {
                  return currentFormData.navigationCb()
                }
                return navigate(currentFormData.addUrl)
              }}>
              <span className="dx-button-text">Add</span>
            </Button>
          )}
        </Item>
        <Item
          location={'after'}
          locateInMenu={'auto'}
          menuItemTemplate={'userPanelTemplate'}>
          <Button
            className={'user-button authorization'}
            width={210}
            height={'100%'}
            stylingMode={'text'}>
            <UserPanel menuMode={'context'} />
          </Button>
        </Item>
        <Template name={'userPanelTemplate'}>
          <UserPanel menuMode={'list'} />
        </Template>
      </Toolbar>
    </header>
  )
}
