import {
  AccountAdvisorsDetail,
  AccountAgentsDetail,
  AccountLendersDetail,
  AccountsListAdvisor,
  AccountsListAgent,
  AccountsListLender,
  GlobalSettings,
  HomePage,
  ReportsGrid,
  Sources,
  StateData,
  Transactions,
  TransactionsByService,
  UserPortaBuyHomeDetail,
  UserPortalAddMember,
  UserPortalAdvisorDetail,
  UserPortalAgentDetail,
  UserPortalLenderDetail,
  UserPortalMemberDetail,
  UserPortalMembersAll,
  UserPortalMembersByServiceTypeId,
  UserPortalRefinanceDetail
} from './pages'
import {withNavigationWatcher} from './contexts/navigation'
import {AccountRouteParam} from 'shared/enums'

const routes = [
  {
    path: '/dashboard',
    element: HomePage
  },
  {
    path: '/reports/transactions',
    element: Transactions
  },
  {
    path: '/reports/transactions-by-service',
    element: TransactionsByService
  },
  {
    path: `/reports/:type`,
    element: ReportsGrid
  },
  {
    path: `/accounts/${AccountRouteParam.HqStaff}`,
    element: AccountsListAdvisor
  },
  {
    path: `/accounts/${AccountRouteParam.Lenders}`,
    element: AccountsListLender
  },
  {
    path: `/accounts/${AccountRouteParam.Agents}`,
    element: AccountsListAgent
  },
  {
    path: `/accounts/${AccountRouteParam.HqStaff}/:id`,
    element: AccountAdvisorsDetail
  },
  {
    path: `/accounts/${AccountRouteParam.Lenders}/:id`,
    element: AccountLendersDetail
  },
  {
    path: `/accounts/${AccountRouteParam.Agents}/:id`,
    element: AccountAgentsDetail
  },
  {
    path: '/state-data',
    element: StateData
  },
  {
    path: '/sources',
    element: Sources
  },
  {
    path: '/global-data',
    element: GlobalSettings
  },
  {
    path: '/user-portal',
    element: UserPortalMembersAll
  },
  {
    path: '/user-portal/:serviceTypeId',
    element: UserPortalMembersByServiceTypeId
  },
  {
    path: '/user-portal/1/:id',
    element: UserPortalMemberDetail
  },
  {
    path: '/user-portal/5/:id',
    element: UserPortaBuyHomeDetail
  },
  {
    path: '/user-portal/6/:id',
    element: UserPortalLenderDetail
  },
  {
    path: '/user-portal/7/:id',
    element: UserPortalAgentDetail
  },
  {
    path: '/user-portal/3/:id',
    element: UserPortalRefinanceDetail
  },
  {
    path: '/user-portal/8/:id',
    element: UserPortalAdvisorDetail
  },
  {
    path: '/user-portal/create/:id',
    element: UserPortalAddMember
  }
]

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element)
  }
})
