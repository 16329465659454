import axios from 'axios'
import {getUserQuery, loginQuery, passwordRecovery} from './queries'

export async function signIn(email: string, password: string) {
  const params = {
    email: email,
    password: password
  }
  return await loginQuery(params)
    .then((response: any) => {
      if (response.data.entity.token) {
        sessionStorage.setItem('authToken', response.data.entity.token)
        axios.defaults.headers.common.authorization = `Bearer ${response.data.entity.token}`
        return {
          isOk: true,
          data: {
            userName: `${response.data.entity.firstName} ${response.data.entity.lastName}`,
            avatarUrl: '',
            userTypeId: response.data.entity.userTypeId,
            userId: response.data.entity.userId,
            permissionLevel: response.data.entity.permissionLevel
          }
        }
      } else {
        return {
          isOk: false,
          message: 'Authentication failed'
        }
      }
    })
    .catch(() => {
      return {
        isOk: false,
        message: 'Authentication failed'
      }
    })
}

export async function getUser() {
  return await getUserQuery()
    .then((response: any) => {
      if (response.data.entity.userId) {
        return {
          isOk: true,
          data: {
            userName: `${response.data.entity.firstName} ${response.data.entity.lastName}`,
            avatarUrl: '',
            userTypeId: response.data.entity.userTypeId,
            userId: response.data.entity.userId,
            permissionLevel: response.data.entity.permissionLevel
          }
        }
      } else {
        return {
          isOk: false
        }
      }
    })
    .catch(() => {
      return {
        isOk: false
      }
    })
}

export async function createAccount(email: string, password: string) {
  try {
    // Send request

    return {
      isOk: true
    }
  } catch {
    return {
      isOk: false,
      message: 'Failed to create account'
    }
  }
}

export async function changePassword(email: string, recoveryCode?: string) {
  try {
    // Send request

    return {
      isOk: true
    }
  } catch {
    return {
      isOk: false,
      message: 'Failed to change password'
    }
  }
}

export async function resetPassword(email: string) {
  return await passwordRecovery(email)
    .then((response: any) => {
      return {
        isOk: true
      }
    })
    .catch(() => {
      return {
        isOk: false
      }
    })
}
