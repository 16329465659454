import {FC, useEffect} from 'react'
import {useHubConnection} from 'shared/hooks/use-hub-connection'

interface ICloseChatToast {
  tabs: any
  setTabs: any
  setChatVisible: any
  setChatData: any
  setMessage: any
}

export const CloseChatToast: FC<ICloseChatToast> = ({
  tabs,
  setTabs,
  setChatVisible,
  setMessage,
  setChatData
}) => {
  const hubConnection = useHubConnection()

  useEffect(() => {
    if (hubConnection) {
      const { closeChatHubMessage, setCloseChatHubMessage } = hubConnection
      if (closeChatHubMessage) {
        const newTabs = tabs.filter((tab: any) => {
          return closeChatHubMessage.pubThreadId !== tab.threadId
        })
        setTabs(newTabs)
        if (!newTabs.length) {
          setChatVisible(false)
        }
        setMessage(false)
        setChatData('')
        setCloseChatHubMessage('')
      }
    }
  }, [hubConnection])

  return <></>
}
