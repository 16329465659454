import type { FC } from 'react'
import {Position, Toast} from 'devextreme-react/toast'

type MessageToastProps = {
  visible: boolean
  message: string
  type?: 'info' | 'warning' | 'error' | 'success'
  onHiding: () => void
}

const ToastParentId = '.content'

export const MessageToast: FC<MessageToastProps> = ({
  visible,
  message,
  type = 'success',
  onHiding
}) => {
  return (
    <Toast
      style={{ cursor: 'pointer' }}
      visible={visible}
      width={300}
      type={type}
      message={message}
      closeOnClick
      onHiding={onHiding}
      displayTime={2 * 60 * 1000}>
      <Position my="right top" at="right top" of={ToastParentId} />
    </Toast>
  )
}
