import { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { createStore } from 'devextreme-aspnet-data-nojquery'
import useFormDataHandlerContext from '../../../shared/hooks/use-form-data-handler-context'
import DataGrid, { Column, FilterRow } from 'devextreme-react/data-grid'
import { useAuth } from '../../../contexts/auth'
import { Link } from 'react-router-dom'
import { apiDomain } from 'api/queries'

const ListContainer = styled.div`
  margin: 40px 40px 60px;
`

export const UsersList = () => {
  const { currentFormData, setCurrentFormData } = useFormDataHandlerContext()
  const { user } = useAuth()

  useEffect(() => {
    const currentFormDataNew = Object.assign({}, currentFormData)
    currentFormDataNew.isFormPage = false
    setCurrentFormData(currentFormDataNew)
  }, [])

  const authToken = sessionStorage.getItem('authToken')

  const { current: dataSourceRef } = useRef(
    createStore({
      key: 'memberId',
      loadUrl: `${apiDomain}/Api/Admin/Member/List/?CallerId=${user?.userTypeId}&CallerTypeId=${user?.userId}`,
      onBeforeSend: (_, ajaxSettings) => {
        ajaxSettings.headers = {
          Authorization: `Bearer ${authToken}`
        }
      }
    })
  )

  return (
    <>
      <ListContainer>
        <DataGrid
          dataSource={dataSourceRef}
          keyExpr="memberId"
          showBorders={true}>
          <FilterRow visible={true}></FilterRow>
          <Column
            dataField="memberId"
            width={100}
            allowSorting={false}
            caption="ID"
          />
          <Column
            dataField="firstName"
            allowSorting={false}
            cellRender={(props) => (
              <Link to={`${props.data.memberId}`}>{props.value}</Link>
            )}
          />
          <Column
            dataField="lastName"
            allowSorting={false}
            cellRender={(props) => (
              <Link to={`${props.data.serviceTypeId}/${props.data.memberId}`}>
                {props.value}
              </Link>
            )}
          />
        </DataGrid>
      </ListContainer>
    </>
  )
}
